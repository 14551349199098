import moment from 'moment';

/**
 * Checks whether the given date matches the given format
 * @param {string} dateString
 * @param {string} dateFormat - moment compatable date format
 */
const isValidDateFormat = (dateString, dateFormat) => moment(dateString, dateFormat, true).isValid();

/**
 *
 * @param {Object} input - key/value of last changed input
 * @param {Object} startEndDates - both startDate & endDate props should be set otherwise defaults to undefined
 *
 * @returns {Object} keys: startDate, endDate; values set true if error is detected.
 */
function validateStartEndDates(input, startEndDates = {}, dateFormat, isCheckSameDate = false) {
  const { startDate, endDate } = startEndDates;
  let isSameDate = moment(startDate).isSame(endDate);
  const errors = { startDate: false, endDate: false, message: '' };

  if (dateFormat) {
    errors.startDate = !!startDate && !isValidDateFormat(startDate, dateFormat);
    errors.endDate = !!endDate && !isValidDateFormat(endDate, dateFormat);
  }

  if ((input.startDate && errors.startDate) || (input.endDate && errors.endDate)) {
    errors.message = 'Invalid date format';
    return errors;
  }

  if (isCheckSameDate) {
    isSameDate = false;
  }

  if (input.startDate) {
    if (isSameDate || moment(endDate).isBefore(startDate)) {
      errors.startDate = true;
      errors.message = isSameDate ? 'Start date cannot be the same as end date' : 'Start date cannot be after end date';
    }
  } else if (input.endDate) {
    if (isSameDate || moment(endDate).isBefore(startDate)) {
      errors.endDate = true;
      errors.message = isSameDate
        ? 'End date cannot be the same as start date'
        : 'End date cannot be before start date';
    }
  }

  return errors;
}

export default validateStartEndDates;
