import { put } from 'redux-saga/effects';
import { loadOfflineLicenseStatusTimer } from '../../../../actions/offlineContentPlayer';

function* getOfflineLicenseStatusTime({ userId }) {
  const storedValue = localStorage.getItem('offline_license_status_time');
  const offlineLicenseStatusTimer = storedValue ? JSON.parse(storedValue) : {};
  yield put(loadOfflineLicenseStatusTimer(offlineLicenseStatusTimer[userId]));
}

export default getOfflineLicenseStatusTime;
