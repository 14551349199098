import { put } from 'redux-saga/effects';
import getApiContentFetch from '../../../apiCalls/ocp/getOfflinePendingActions';
import {
  loadOfflinePendingActionsSuccess,
  loadOfflinePendingActionsFailure
} from '../../../../actions/offlineContentPlayer';

function* getOfflinePendingActions(params) {
  const response = yield getApiContentFetch(params);
  if (response.status === 'success') {
    yield put(loadOfflinePendingActionsSuccess(response.data));
  } else {
    yield put(loadOfflinePendingActionsFailure('Failed to get CPT content'));
  }
}

export default getOfflinePendingActions;
