const appSettings = {
  appSettingsLoaded: false,

  // Currently not loaded
  // eslint-disable-next-line max-len
  URL_REGEX: /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www\.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w\-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[.!/\\\w]*))?)/,
  INVALID_CHARACTERS_REGEX: /[~`!£()@#$%^&*+=[\]\\;,/{}|\\":<>?]/,
  INVALID_PERIOD_PLACEMENT_REGEX: /[\\.]{2,}|^\.|\.$/,
  ACCESS_CODE_REGEX: /^(([a-zA-Z\d]{4}-){2}[a-zA-Z\d]{4}|[a-zA-Z\d]{12})$/,
  ACCESS_CODE_VST_REGEX: /^([ASTast]){1}(((-[\d]{3}){2}-[\d]{4})|([\d]{10}))$/,

  // Regex defaults
  PASSWORD_REGEX: null,
  EMAIL_REGEX: null,
  NAME_REGEX: null,
  GOOGLE_RECAPTCHA_REGEX: /[0-9a-zA-Z_-]{40}/,
  activationCodeCSVTemplate: null,
  AppMode: null
};

export default appSettings;

/**
 * The maximum length for a single username segment. A long source firstName or
 * lastName value will be truncated at this size in the resulting username.
 *
 * @type {number}
 */
export const USERNAME_MAX_SEGMENT_LENGTH = 20;

// add settings that are shared with the Lambdas
export function setAppSettings(settingsObject) {
  Object.keys(settingsObject).forEach(key => {
    if (appSettings[key] !== undefined) {
      console.warn(`Overwriting appSetting ${key} with sharedSetting: '${settingsObject[key]}'`);
    }

    appSettings[key] = settingsObject[key];
  });

  // convert _REGEXP strings to Regular Expression objects
  Object.keys(appSettings).forEach(key => {
    const isRegularExpressionAsString = key.indexOf('_REGEX') >= 0 && typeof appSettings[key] === 'string';

    if (isRegularExpressionAsString) {
      appSettings[key] = new RegExp(appSettings[key]);
    }
  });

  // trigger loaded event
  Object.assign(appSettings, {
    appSettingsLoaded: true
  });
}
