import * as t from '../../actionTypes';

const initialState = {
  loading: false,
  accessCode: '',
  valid: false,
  error: null
};

export default function validateAccessCode(state = initialState, action) {
  switch (action.type) {
    case t.VALIDATE_ACCESS_CODE_REQUEST:
      return {
        ...state,
        accessCode: action.data.accessCode,
        loading: true
      };
    case t.VALIDATE_ACCESS_CODE_SUCCESS:
      return {
        ...state,
        error: '',
        loading: false,
        valid: true
      };
    case t.VALIDATE_ACCESS_CODE_FAILURE:
      return {
        ...state,
        error: action.data.error,
        loading: false,
        valid: false
      };
    case t.VALIDATE_ACCESS_CODE_RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
}
