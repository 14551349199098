import * as t from '../../actionTypes';

const initialState = {
  failedAttempts: 0,
  form: {
    errors: {
      testTakerName: { value: false, type: null },
      dateOfBirth: { value: false, type: null },
      testTakerNumber: { value: false, type: null },
      certificateReferenceNumber: { value: false, type: null },
      certificateType: { value: false, type: null },
      overallScore: { value: false, type: null },
      overallCefrLevel: { value: false, type: null },
      speakingScore: { value: false, type: null },
      speakingCefrLevel: { value: false, type: null },
      speakingDateTaken: { value: false, type: null },
      speakingTestCentreID: { value: false, type: null },
      listeningScore: { value: false, type: null },
      listeningCefrLevel: { value: false, type: null },
      listeningDateTaken: { value: false, type: null },
      listeningTestCentreID: { value: false, type: null },
      readingScore: { value: false, type: null },
      readingCefrLevel: { value: false, type: null },
      readingDateTaken: { value: false, type: null },
      readingTestCentreID: { value: false, type: null },
      writingScore: { value: false, type: null },
      writingCefrLevel: { value: false, type: null },
      writingDateTaken: { value: false, type: null },
      writingTestCentreID: { value: false, type: null }
    },
    submitting: false,
    success: false,
    failure: false,
    bot: false,
    unavailableApi: false
  },
  upload: {
    submitting: false,
    success: false,
    failure: false,
    bot: false
  }
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case t.RVS_FORM_VALIDATE_COMPLETED:
      return {
        ...state,
        form: {
          ...state.form,
          errors: {
            ...state.form.errors,
            ...payload
          }
        }
      };
    case t.RVS_FORM_SUBMIT:
      return {
        ...state,
        form: {
          ...state.form,
          submitting: true
        }
      };
    case t.RVS_FORM_SUCCESS:
      return {
        ...state,
        form: {
          ...state.form,
          submitting: false,
          success: true
        }
      };
    case t.RVS_FORM_FAILURE:
      return {
        ...state,
        form: {
          ...state.form,
          submitting: false,
          failure: true
        }
      };
    case t.RVS_FORM_RESET:
      return {
        ...state,
        form: {
          ...state.form,
          submitting: false,
          success: false,
          failure: false,
          bot: false,
          unavailableApi: false
        }
      };
    case t.RVS_FORM_BOT:
      return {
        ...state,
        form: {
          ...state.form,
          submitting: false,
          success: false,
          bot: true
        }
      };
    case t.RVS_UNAVAILABLE_API:
      return {
        ...state,
        form: {
          ...state.form,
          submitting: false,
          unavailableApi: true
        }
      };
    case t.RVS_UPLOAD_SUBMIT:
      return {
        ...state,
        upload: {
          ...state.upload,
          submitting: true
        }
      };
    case t.RVS_UPLOAD_SUCCESS:
      return {
        ...state,
        upload: {
          ...state.upload,
          submitting: false,
          invalid: false,
          error: false,
          serviceUnavailable: false,
          success: true
        }
      };
    case t.RVS_UPLOAD_ERROR:
      return {
        ...state,
        upload: {
          ...state.upload,
          errors: {
            ...payload
          },
          submitting: false,
          success: false,
          invalid: false,
          serviceUnavailable: false,
          error: true
        }
      };
    case t.RVS_UPLOAD_INVALID:
      return {
        ...state,
        upload: {
          ...state.upload,
          submitting: false,
          success: false,
          error: false,
          serviceUnavailable: false,
          invalid: true
        }
      };
    case t.RVS_UPLOAD_RESET:
      return {
        ...state,
        upload: {
          ...state.upload,
          submitting: false,
          success: false,
          error: false,
          serviceUnavailable: false,
          invalid: false
        }
      };
    case t.RVS_UPLOAD_SERVICE_UNAVAILABLE:
      return {
        ...state,
        upload: {
          ...state.upload,
          submitting: false,
          success: false,
          error: false,
          serviceUnavailable: true,
          invalid: false
        }
      };
    default:
      return state;
  }
};
