import { put } from 'redux-saga/effects';
import actions from '../../../../actions';
import signedFetch from '../../../apiCalls/util/signedFetch';

function* getLicenceCreditsProducts(orgId) {
  const response = yield signedFetch(
    'getLicenceCreditsProducts',
    `${__API_BASE__}/ngi/org/${orgId.orgId}/productCredits`
  );

  if (response.data?.length > 0) {
    return yield put(actions.getLicenceCreditsProductsSucces({ licenceCreditsProducts: response.data }));
  }
  return yield put(actions.getLicenceCreditsProductsFailure({ errors: response.error }));
}

export default getLicenceCreditsProducts;
