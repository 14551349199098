import { put } from 'redux-saga/effects';
import { loadOfflineLicenseStatusTimer } from '../../../../actions/offlineContentPlayer';

function* setOfflineLicenseStatusTime({ offlineUserId, currentTime }) {
  const storedValue = localStorage.getItem('offline_license_status_time');
  const offlineLicenseStatusTimer = storedValue ? JSON.parse(storedValue) : {};

  offlineLicenseStatusTimer[offlineUserId] = currentTime;
  localStorage.setItem('offline_license_status_time', JSON.stringify(offlineLicenseStatusTimer));

  yield put(loadOfflineLicenseStatusTimer(offlineLicenseStatusTimer));
}

export default setOfflineLicenseStatusTime;
