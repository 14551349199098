import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { debounce } from 'lodash';

// Action imports
import withLocalizedContent from '../../../language/withLocalizedContent';
import { searchTypes, productFinderFormStates } from '../../../redux/reducers/productFinder.reducer';
import {
  getProductFinderProducts,
  getProductFinderSeries,
  getProductFinderProductsForSeriesRequest,
  selectMultipleProducts,
  clearSelectedProducts,
  selectProductFinderSingleProduct,
  setSelectedCountryCode,
  changeSearchType,
  setFormState,
  setSearchState
} from '../../../redux/actions/productFinderActions';

// Component imports
import SelectedRow from '../../SelectedRow/SelectedRow';
import PanelHeading from '../../PanelHeading/PanelHeading.js';
import ScrollContainer from '../../ScrollContainer/ScrollContainer.js';
import SVGIcon, { GLYPHS } from '../../SVGIcon/SVGIcon.js';
import HubEmptyStateRestyle from '../../HubEmptyStateRestyle/HubEmptyStateRestyle';
import Button from '../../Button/Button.js';
import PanelNavigationLink from '../../PanelNavigationLink/PanelNavigationLink.js';
import ControlledForm from '../../ControlledForm/ControlledForm.js';
import Label from '../../Label/Label.js';
import DropdownLink from '../../DropdownLink/DropdownLink.js';
import TextInputWithButton from '../../TextInputWithButton/TextInputWithButton';
import SubSectionSkeletonLoader from '../../SkeletonLoader/SubSectionSkeletonLoader';
import ProductFinderProductResults from '../ProductFinderProductResults.js';
import ProductFinderSeriesResults from '../ProductFinderSeriesResults.js';

// Constants
import { HubIllustrationConstants, HubIllustrationAltText, HubLayoutConstants } from '../../../globals/hubConstants';

// Utils
import cmsContent from '../../../utils/cmsContent.js';

// Style
import styles from './ProductFinderFormStates.scss';
import colors from '../../../globals/colors';
import PopoutNavFooter from '../../PopoutNavFooter/PopoutNavFooter';

function ProductFinderMaterialSearch({
  getProductFinderProductsAction,
  getProductFinderSeriesAction,
  products,
  series,
  closePopoutAction,
  loadingProducts,
  loadingSeries,
  error,
  contextName,
  getProductFinderProductsForSeriesAction,
  productFinderContent,
  localizedContent: { countryCodes },
  selectedProducts = [],
  selectMultipleProductsAction,
  clearSelectedProductsAction,
  selectSingleProductAction,
  changeSearchTypeAction,
  selectedCountryCode,
  searchType,
  searchState,
  setFormStateAction,
  setSearchStateAction,
  setSelectedCountryCodeAction
}) {
  const CMS = cmsContent.assignLearningMaterialPanel || {};
  const selectionLimit = HubLayoutConstants.PRODUCT_SELECTION_LIMIT;

  const [displayedCountryCodes, setDisplayedCountryCodes] = useState([]);
  const [searchReset, setSearchReset] = useState(false);
  const [seriesFilteredByCountry, setSeriesFilteredByCountry] = useState(series);
  const [productsFilteredByCountry, setProductsFilteredByCountry] = useState(products);

  const seriesSearchTerm = searchState[searchTypes.SERIES].searchTerm;
  const productSearchTerm = searchState[searchTypes.PRODUCTS].searchTerm;

  const productsLoaded = !loadingProducts && !error;
  const seriesLoaded = !loadingSeries && !error;
  const hasProductResults = productsLoaded && products.length > 0 && productsFilteredByCountry.length > 0;
  const hasSeriesResults = seriesLoaded && series.length > 0 && seriesFilteredByCountry.length > 0;

  const triggerSeriesSearch = useMemo(() => debounce(value => getProductFinderSeriesAction(value), 350), []);

  const triggerProductSearch = useMemo(() => debounce(value => getProductFinderProductsAction(value), 350), []);

  const handleSeriesSearchChange = searchTerm => {
    setSearchStateAction({ [searchTypes.SERIES]: { searchTerm } });
    triggerSeriesSearch(searchTerm);
  };

  const handleProductSearchChange = searchTerm => {
    setSearchStateAction({ [searchTypes.PRODUCTS]: { searchTerm } });
    triggerProductSearch(searchTerm);
  };

  const resetSeriesSearch = () => {
    setSearchReset(true);
    getProductFinderSeriesAction();
    setSearchStateAction({ [searchTypes.SERIES]: { searchTerm: '' } });
  };

  const resetProductSearch = () => {
    setSearchReset(true);
    getProductFinderProductsAction();
    setSearchStateAction({ [searchTypes.PRODUCTS]: { searchTerm: '' } });
  };

  useEffect(() => {
    if (!hasSeriesResults) getProductFinderSeriesAction();
    if (!hasProductResults) getProductFinderProductsAction();

    const generatedCountryCodes = [
      {
        text: productFinderContent.all,
        value: 'UNIVERSAL'
      },
      ...Object.entries(countryCodes).map(([code, text]) => ({
        text,
        value: code
      }))
    ];
    setDisplayedCountryCodes(generatedCountryCodes);
  }, []);

  useEffect(() => {
    if (searchReset) {
      setSearchReset(false);
    }
  }, [searchReset]);

  useEffect(() => {
    if (selectedCountryCode === 'UNIVERSAL') {
      setSeriesFilteredByCountry(series);
      setProductsFilteredByCountry(products);
    } else {
      setSeriesFilteredByCountry(
        series.filter(
          s =>
            !s.countryCodes || s.countryCodes.includes(selectedCountryCode) || s.countryCodes.includes('INTERNATIONAL')
        )
      );

      setProductsFilteredByCountry(
        products.filter(
          product =>
            !product['audience:country_code'] ||
            product['audience:country_code'] === selectedCountryCode ||
            product['audience:country_code'] === 'INTERNATIONAL'
        )
      );
    }
  }, [selectedCountryCode, products, series]);

  useEffect(
    () => () => {
      triggerSeriesSearch.cancel();
      triggerProductSearch.cancel();
    },
    []
  );

  const _renderNavigationPills = () => (
    <div className={styles.navPillsContainer}>
      <div className={styles.navPills}>
        <button
          type="button"
          className={searchType === searchTypes.SERIES ? styles.active : ''}
          onClick={() => changeSearchTypeAction(searchTypes.SERIES)}
        >
          {productFinderContent.browse_by_series}
        </button>
        <button
          type="button"
          className={searchType === searchTypes.PRODUCTS ? styles.active : ''}
          onClick={() => changeSearchTypeAction(searchTypes.PRODUCTS)}
        >
          {productFinderContent.browse_by_products}
        </button>
      </div>
    </div>
  );

  const getEmptyState = resetButtonAction => (
    <div className={styles.emptyStateProductFinder}>
      <HubEmptyStateRestyle
        iconSrc={HubIllustrationConstants.INFORMATION}
        iconAlt={HubIllustrationAltText.INFORMATION}
        title={productFinderContent.no_results_found_title}
        bodyText={productFinderContent.no_results_found_subtitle}
        btnFilledBase={{
          text: productFinderContent.reset_filters,
          action: () => resetButtonAction(),
          icon: GLYPHS.ICON_REFRESH
        }}
      />
    </div>
  );
  const renderSelectedProductsFooter = () => (
    <>
      {!!selectedProducts.length && (
        <div className={styles.productsSelectedContainer}>
          <SelectedRow content={productFinderContent} selectedIds={selectedProducts} maximumAllowed={selectionLimit} />
          <div className={styles.productsUnselectLinkContainer}>
            <PanelNavigationLink
              noGlyph
              text={productFinderContent.clear_selected}
              action={clearSelectedProductsAction}
            />
          </div>
        </div>
      )}
      <PopoutNavFooter
        nextAction={() => {
          selectSingleProductAction(selectedProducts[0]);
          setFormStateAction(productFinderFormStates.REVIEW_LICENCES);
        }}
        nextButtonText={productFinderContent.next_label}
        nextButtonDisabled={!selectedProducts.length}
      />
    </>
  );
  return (
    <>
      <div className={styles.headerProductFinderSearch}>
        <div className={styles.textRightRow}>
          <PanelNavigationLink isLhs={false} text={CMS.close_panel_text} action={closePopoutAction} />
        </div>
        <PanelHeading
          title={productFinderContent.find_learning_material_title}
          subtitle={`${productFinderContent.for} {assigneeName}`.replace('{assigneeName}', contextName)}
        />
        {_renderNavigationPills()}
      </div>

      {searchType === searchTypes.SERIES && (
        <>
          <div className={styles.searchFormContainer}>
            <TextInputWithButton
              id="seriesSearchTerm"
              placeholder={productFinderContent.search_series_placeholder}
              onChange={value => handleSeriesSearchChange(value)}
              buttonAction={() => handleSeriesSearchChange(seriesSearchTerm)}
              value={seriesSearchTerm}
            />
          </div>

          <ControlledForm className={styles.countrySelectorContainer}>
            <Label text={productFinderContent.country_label} className={styles.countryLabel} htmlFor="countryNames" />
            <DropdownLink
              options={displayedCountryCodes}
              onChange={countryCode => setSelectedCountryCodeAction(countryCode)}
              value={selectedCountryCode}
              customClassName={styles.countryPicker}
            />
          </ControlledForm>

          <div className={styles.scrollContainerOuter}>
            <ScrollContainer role="search" footerContent={renderSelectedProductsFooter()}>
              <div>
                {loadingSeries ? (
                  <SubSectionSkeletonLoader
                    largeRectWidth="60%"
                    smallRectWidth="40%"
                    speed={2}
                    foregroundColor={colors.COLOR_GREY_DISABLED2}
                    backgroundColor={colors.COLOR_WHITE}
                  />
                ) : null}

                {error ? (
                  <div className={styles.textCenterRow}>
                    <div className={styles.iconContainer}>
                      <SVGIcon glyph={GLYPHS.ICON_ERROR_CIRCLE} />
                    </div>
                    <p className={styles.textContainer}>{CMS.error_search_results_text}</p>
                    <Button text={CMS.retry_button_text} onClick={getProductFinderProductsAction} />
                  </div>
                ) : null}

                {hasSeriesResults && (
                  <ProductFinderSeriesResults
                    series={seriesFilteredByCountry}
                    onSeriesClick={selectedSeries => {
                      setFormStateAction(productFinderFormStates.SERIES_OVERVIEW);
                      getProductFinderProductsForSeriesAction(selectedSeries);
                    }}
                  />
                )}

                {seriesLoaded && !hasSeriesResults && getEmptyState(resetSeriesSearch)}
              </div>
            </ScrollContainer>
          </div>
        </>
      )}

      {searchType === searchTypes.PRODUCTS && (
        <>
          <div className={styles.searchFormContainer}>
            <TextInputWithButton
              id="productSearchTerm"
              placeholder={productFinderContent.search_products_placeholder}
              onChange={value => handleProductSearchChange(value)}
              buttonAction={() => handleProductSearchChange(productSearchTerm)}
              value={productSearchTerm}
            />
          </div>

          <ControlledForm className={styles.countrySelectorContainer}>
            <Label text={productFinderContent.country_label} className={styles.countryLabel} htmlFor="countryNames" />
            <DropdownLink
              options={displayedCountryCodes}
              onChange={countryCode => setSelectedCountryCodeAction(countryCode)}
              value={selectedCountryCode}
              customClassName={styles.countryPicker}
              labelHidden
            />
          </ControlledForm>

          <div className={styles.scrollContainerOuter}>
            <ScrollContainer role="search" footerContent={renderSelectedProductsFooter()}>
              <div>
                {loadingProducts ? (
                  <SubSectionSkeletonLoader
                    largeRectWidth="60%"
                    smallRectWidth="40%"
                    speed={2}
                    foregroundColor={colors.COLOR_GREY_DISABLED2}
                    backgroundColor={colors.COLOR_WHITE}
                  />
                ) : null}

                {error ? (
                  <div className={styles.textCenterRow}>
                    <div className={styles.iconContainer}>
                      <SVGIcon glyph={GLYPHS.ICON_ERROR_CIRCLE} />
                    </div>
                    <p className={styles.textContainer}>{CMS.error_search_results_text}</p>
                    <Button text={CMS.retry_button_text} onClick={getProductFinderProductsAction} />
                  </div>
                ) : null}

                {hasProductResults && (
                  <ProductFinderProductResults
                    products={productsFilteredByCountry}
                    searchType={searchTypes.PRODUCTS}
                    onProductClick={(productId, productSelected) => {
                      if (productId?.length && typeof productId !== 'string') {
                        return selectMultipleProductsAction(productId, productSelected);
                      }
                      return selectMultipleProductsAction([productId], productSelected);
                    }}
                    productFinderContent={productFinderContent}
                    selectedProducts={selectedProducts}
                  />
                )}

                {productsLoaded && !hasProductResults && getEmptyState(resetProductSearch)}
              </div>
            </ScrollContainer>
          </div>
        </>
      )}
    </>
  );
}

ProductFinderMaterialSearch.propTypes = {
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  series: PropTypes.array,
  closePopoutAction: PropTypes.func,
  loadingProducts: PropTypes.bool,
  loadingSeries: PropTypes.bool,
  contextName: PropTypes.string,
  error: PropTypes.bool,
  productFinderContent: PropTypes.object,
  // Actions
  getProductFinderProductsForSeriesAction: PropTypes.func,
  getProductFinderProductsAction: PropTypes.func,
  getProductFinderSeriesAction: PropTypes.func,
  localizedContent: PropTypes.object,
  selectedProducts: PropTypes.array,
  selectMultipleProductsAction: PropTypes.func,
  clearSelectedProductsAction: PropTypes.func,
  selectSingleProductAction: PropTypes.func,
  changeSearchTypeAction: PropTypes.func,
  setSelectedCountryCodeAction: PropTypes.func,
  selectedCountryCode: PropTypes.string,
  searchType: PropTypes.string,
  searchState: PropTypes.object,
  setFormStateAction: PropTypes.func,
  setSearchStateAction: PropTypes.func
};

export default compose(
  withLocalizedContent('countryCodes'),
  connect(
    state => ({
      products: state.productFinder.products,
      series: state.productFinder.series,
      error: state.productFinder.error,
      loadingProducts: state.productFinder.loadingProducts,
      loadingSeries: state.productFinder.loadingSeries,
      selectedCountryCode: state.productFinder.selectedCountryCode,
      selectedProducts: state.productFinder.selectedProducts,
      searchType: state.productFinder.searchType,
      searchState: state.productFinder.searchState
    }),
    {
      getProductFinderProductsForSeriesAction: getProductFinderProductsForSeriesRequest,
      getProductFinderProductsAction: getProductFinderProducts,
      getProductFinderSeriesAction: getProductFinderSeries,
      selectMultipleProductsAction: selectMultipleProducts,
      clearSelectedProductsAction: clearSelectedProducts,
      selectSingleProductAction: selectProductFinderSingleProduct,
      setSelectedCountryCodeAction: setSelectedCountryCode,
      changeSearchTypeAction: changeSearchType,
      setFormStateAction: setFormState,
      setSearchStateAction: setSearchState
    }
  )
)(ProductFinderMaterialSearch);
