import classnames from 'classnames';
import { omit, debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import prefixKeys from '../../utils/object/prefixKeys';
// @ts-ignore
import styles from './TextInputField.scss';
import { glyphFor } from '../ValidationStatus/ValidationStatusCodes';
import { validationStatusPropType } from '../../types/propTypeDefinitions';
import SVGIcon from '../SVGIcon/SVGIcon';

const TYPE_STOP_DELAY = 1000;

/** This component is for the input field only. It should always be used with TextInputFieldLabel. */
class TextInputField extends Component {
  constructor(props) {
    super(props);

    this._updateInput = debounce(this._updateInput, TYPE_STOP_DELAY);
  }

  componentDidUpdate(prevProps) {
    const { autoFocus } = this.props;
    if (!prevProps.autoFocus && autoFocus) {
      this._input.focus();
    }
  }

  // The input debounce function, called when typing has stopped
  _updateInput(input, callback) {
    this._updateInput = this._updateInput.bind(this);
    // eslint-disable-next-line no-unused-expressions
    input !== '' ? callback(input) : false;
  }

  // Note: The only reason we wrap this in a <div> is to make the tooltip css work.
  // It relies on css :before and :after which cannot be applied to <input> elements.
  render() {
    const {
      autoHighlight = false,
      autoCapitalize,
      title,
      infoText = '',
      value,
      onClick,
      onChange,
      onKeyUp,
      onTypeStop,
      onBlur,
      onKeyDown,
      type = 'text',
      disabled = false,
      readOnly = false,
      readOnlyPreserveStyle = false,
      required = true, // Note: Textboxes default to REQUIRED throughout the app
      disableAutoComplete = false,
      textArea = false,
      aria,
      customStyleName,
      onFocus,
      readonlyMultiline = false,
      ariaLabel,
      validationStatus,
      ...props
    } = this.props;

    const _onChange = ({ target }) => {
      if (onTypeStop) this._updateInput(target.value, onTypeStop);
      if (onChange) onChange(target.value, target.name);
    };

    const _onFocus = ({ target }) => {
      if (onFocus) onFocus(target.name);
    };

    // If aria attributes were supplied, ensure they all have a prefix of "aria-":
    const ariaAttrs = prefixKeys(aria, 'aria-');

    const Tag = textArea ? 'textarea' : 'input';

    return (
      <div
        title={title}
        className={styles.textInputFieldWrapper}
        {...(!!validationStatus && { style: { display: 'flex' } })}
      >
        {readOnly ? (
          <div
            aria-hidden="true"
            onClick={onClick ? () => onClick() : undefined}
            className={classnames({
              [styles.textInputField]: readOnlyPreserveStyle,
              [styles['readonly-multiline']]: readonlyMultiline
            })}
          >
            {readOnlyPreserveStyle ? value : value || 'n/a'}
          </div>
        ) : (
          <>
            {infoText && (
              <small className={styles.infoText} aria-details={infoText}>
                {infoText}
              </small>
            )}
            <Tag
              ref={input => {
                this._input = input;
              }}
              className={classnames(
                styles.textInputField,
                {
                  [styles.textArea]: textArea
                },
                customStyleName
              )}
              type={type}
              autoComplete={disableAutoComplete ? 'off' : undefined}
              disabled={disabled}
              required={required}
              value={value}
              onChange={_onChange}
              onClick={onClick ? event => onClick(event.target.value) : undefined}
              onKeyUp={onKeyUp}
              onFocus={autoHighlight && !onFocus ? event => event.target.select() : _onFocus}
              onBlur={onBlur ? event => onBlur(event.target.name, event.target.value) : undefined}
              onKeyDown={onKeyDown}
              {...ariaAttrs}
              {...omit(props, ['customStyleName'])}
              autoCapitalize={autoCapitalize}
              aria-label={ariaLabel}
            />
          </>
        )}
        {Object.keys(glyphFor).includes(validationStatus) && (
          <SVGIcon className={styles.validationIcon} glyph={glyphFor[validationStatus]} />
        )}
      </div>
    );
  }
}

TextInputField.propTypes = {
  /** the id of the input field needs to be unique */
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  autoFocus: PropTypes.bool,
  autoHighlight: PropTypes.bool,
  ariaLabel: PropTypes.string,
  /** the caption for the input field */
  infoText: PropTypes?.string,
  /** the inputs placeholder text */
  placeholder: PropTypes.string,
  /** Actual value of the input. If not preset then the placeholder is shown */
  value: PropTypes.any,
  /** The optional tooltip */
  title: PropTypes.string,
  /** function that if the input is touched, will be called */
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onKeyUp: PropTypes.func,
  /** function that fires when typing stops */
  onTypeStop: PropTypes.func,
  /** Function to bind to the native input onBlur event */
  onBlur: PropTypes.func,
  /** Function to bind to the native input onKeyDown event */
  onKeyDown: PropTypes.func,
  /** Function called when user pastes content into the field */
  onPaste: PropTypes.func,
  /** The type attribute of the <input> element. Restricted to text looking inputs (ie not checkbox etc). */
  type: PropTypes.oneOf(['text', 'password', 'number', 'date', 'email']),
  /** the minumum limit for numeric fields */
  min: PropTypes.number,
  /** the maximum limit for numeric fields */
  max: PropTypes.number,
  /** map of aria attribute names and values, eg: aria={{ role:'textbox', live: 'assertive' }} */
  aria: PropTypes.object,
  /** Set this to true to disable the browser's native autoComplete suggestions */
  disableAutoComplete: PropTypes.bool,
  /** Set this to true to disable any interaction on the input field */
  disabled: PropTypes.bool,
  /** Set whether the field is for presentation and is not editable. */
  readOnly: PropTypes.bool,
  /** Set this to false to prevent textbox from defaulting to required, for screenreaders etc */
  required: PropTypes.bool,
  /** Max Length value for the input element */
  maxLength: PropTypes.number,
  /** Make the text box of type text area */
  textArea: PropTypes.bool,
  /** Give the textbox a custom style */
  customStyleName: PropTypes.string,
  readOnlyPreserveStyle: PropTypes.bool,
  autoCapitalize: PropTypes.string,
  onFocus: PropTypes.func,

  readonlyMultiline: PropTypes.bool,
  withValidationIcon: PropTypes.bool,
  errorIcon: PropTypes.string,
  validationStatus: validationStatusPropType
};

export default TextInputField;
