import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';

// Redux
import { productFinderFormStates } from '../../redux/reducers/productFinder.reducer';
import { resetProductFinderState, setSelectedCountryCode } from '../../redux/actions/productFinderActions';

// Components
import ProductFinderMaterialSearch from './formStates/ProductFinderMaterialSearch';
import ProductFinderSeriesOverview from './formStates/ProductFinderSeriesOverview';
import ProductFinderMultipleReviewLicences from './formStates/ProductFinderMultipleReviewLicences';
import ProductFinderViewLicenceInformation from './formStates/ProductFinderViewLicenceInformation';
import ProductFinderSubmitting from './formStates/ProductFinderSubmitting';
import ProductFinderMultipleConfirmation from './formStates/ProductFinderMultipleConfirmation';
import ProductFinderMultipleError from './formStates/ProductFinderMultipleError';

import withLocalizedContent from '../../language/withLocalizedContent';
import withBreakpoint from '../../decorators/withBreakpoint';

function ProductFinderPanel({
  orgId,
  classId,
  product,
  selectedProducts,
  selectedUsers,
  productFinderFormState,
  contextName,
  localizedContent: { productFinder: productFinderContent },
  errorOccurred,
  onClose,
  resetProductFinderStateAction,
  setSelectedCountryCodeAction,
  selectedCountryCode,
  defaultCountryCode,
  breakpoint,
  context,
  userEmail
}) {
  const closePanel = () => {
    resetProductFinderStateAction();
    return onClose();
  };

  useEffect(() => {
    if (!selectedCountryCode) {
      setSelectedCountryCodeAction(defaultCountryCode);
    }
  }, [selectedCountryCode]);
  if (errorOccurred) {
    return (
      <ProductFinderMultipleError
        productFinderContent={productFinderContent}
        onAddAnotherClick={resetProductFinderStateAction}
        closePanel={closePanel}
        contextName={contextName}
      />
    );
  }

  if (productFinderFormState === productFinderFormStates.SEARCHING) {
    return (
      <ProductFinderMaterialSearch
        selectedUsers={selectedUsers}
        productFinderContent={productFinderContent}
        contextName={contextName}
        closePopoutAction={closePanel}
      />
    );
  }

  if (productFinderFormState === productFinderFormStates.SERIES_OVERVIEW) {
    return (
      <ProductFinderSeriesOverview
        selectedUsers={selectedUsers}
        productFinderContent={productFinderContent}
        contextName={contextName}
        closePopoutAction={closePanel}
        breakpoint={breakpoint}
      />
    );
  }

  if (productFinderFormState === productFinderFormStates.REVIEW_LICENCES) {
    return (
      <ProductFinderMultipleReviewLicences
        productFinderContent={productFinderContent}
        selectedProducts={selectedProducts}
        selectedUsers={selectedUsers}
        classId={classId}
        orgId={orgId}
        contextName={contextName}
        closePopoutAction={closePanel}
        context={context}
      />
    );
  }

  if (productFinderFormState === productFinderFormStates.VIEW_PRODUCT_LICENCE_INFO) {
    return (
      <ProductFinderViewLicenceInformation
        productFinderContent={productFinderContent}
        product={product}
        closePopoutAction={closePanel}
      />
    );
  }

  if (productFinderFormState === productFinderFormStates.SUBMITTING) {
    return <ProductFinderSubmitting productFinderContent={productFinderContent} />;
  }

  if (productFinderFormState === productFinderFormStates.CONFIRMATION) {
    return (
      <ProductFinderMultipleConfirmation
        productFinderContent={productFinderContent}
        closePanel={closePanel}
        contextName={contextName}
        userEmail={userEmail}
        selectedProducts={selectedProducts}
      />
    );
  }

  return (
    <div>
      <p>Error. Missing a state for `{productFinderFormState}`.</p>
    </div>
  );
}

export default compose(
  connect(
    state => {
      // product-finder-multi-select
      const firstProduct = state.productFinder.product || state.productFinder.selectedProducts[0];
      const licenceStructureForProduct = state.productFinder?.licenceStructure[firstProduct?.productid] || {};
      return {
        productFinderFormState: state.productFinder.formStateHistory[0],
        product: firstProduct,
        assignLicences: state.productFinder.assignLicences,
        userIdsInOrder: licenceStructureForProduct.userIdsInOrder || [],
        selectedProducts: state.productFinder.selectedProducts,
        errorOccurred: state.productFinder.errorOccurred,
        userEmail: state.identity.email,
        selectedCountryCode: state.productFinder.selectedCountryCode,
        defaultCountryCode:
          state.organisations?.data?.[state.identity?.currentOrganisationId]?.countryCode || 'UNIVERSAL'
      };
    },

    {
      resetProductFinderStateAction: resetProductFinderState,
      setSelectedCountryCodeAction: setSelectedCountryCode
    }
  ),
  withLocalizedContent('productFinder'),
  withBreakpoint
)(ProductFinderPanel);

ProductFinderPanel.propTypes = {
  orgId: PropTypes.string,
  selectedUsers: PropTypes.object,
  contextName: PropTypes.string,
  resetProductFinderStateAction: PropTypes.func,
  productFinderFormState: PropTypes.string,
  product: PropTypes.object,
  selectedProducts: PropTypes.array,
  localizedContent: PropTypes.object,
  selectedCountryCode: PropTypes.string,
  defaultCountryCode: PropTypes.string,
  setSelectedCountryCodeAction: PropTypes.func,
  errorOccurred: PropTypes.bool,
  breakpoint: PropTypes.string,
  classId: PropTypes.string,
  onClose: PropTypes.func,
  context: PropTypes.string,
  userEmail: PropTypes.string
};
