const levelSelector = (levels, hierarchy, isActivityLevel) => {
  let lastIndexTilde;
  if (hierarchy) lastIndexTilde = hierarchy.lastIndexOf('~') + 1;
  const defaultOption = {
    text: lastIndexTilde ? hierarchy.slice(lastIndexTilde) : 'Overview',
    value: 'default',
    disabled: true
  };

  const options = levels.reduce(
    (accumulator, { name, uId }) =>
      uId ? [...accumulator, { text: name, value: uId, disabled: isActivityLevel }] : accumulator,
    []
  );
  const overviewOption = { text: 'Overview', value: '' };
  const filteredOptions = [...(defaultOption.text !== 'Overview' ? [overviewOption] : []), defaultOption, ...options];
  return filteredOptions;
};

export default levelSelector;
