import { put } from 'redux-saga/effects';
import { loadPendingActionsTime } from '../../../../actions/offlineContentPlayer';

function* getOfflinePendingActionsTime({ userId }) {
  const storedValue = localStorage.getItem('offline_pending_actions_time');
  const pendingActions = storedValue ? JSON.parse(storedValue) : {};
  yield put(loadPendingActionsTime(pendingActions[userId]));
}

export default getOfflinePendingActionsTime;
