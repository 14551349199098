import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Checkbox from '@oup/shared-front-end/src/components/Checkbox/Checkbox.js';
import ProductFinderProduct from './ProductFinderProduct';
import usePagination from '../../utils/hooks/usePagination';
import PaginationButtons from '../PaginationButtons/PaginationButtons.js';

import { setSearchState } from '../../redux/actions/productFinderActions';
import { searchTypes } from '../../redux/reducers/productFinder.reducer.js';
import { HubLayoutConstants } from '../../globals/hubConstants.js';
import styles from './ProductFinder.scss';

const orderProductsByLevel = (products, levels) => {
  let productsOrderedByLevel = [];

  levels.forEach(level => {
    const productsFilteredByCurrentLevel = products.filter(product => product['elt:level'] === level);
    productsOrderedByLevel = [...productsOrderedByLevel, ...productsFilteredByCurrentLevel];
  });

  return productsOrderedByLevel;
};

function ProductFinderProductResults({
  products,
  onProductClick,
  productFinderContent,
  levels = [],
  selectedProducts,
  searchState,
  searchType,
  setSearchStateAction
}) {
  const RESULTS_PER_PAGE = 10;
  const initialPage = searchState[searchType].page;
  const [selectionLimitReached, setSelectionLimitReached] = useState(false);
  const { currentPage, setPage, totalPages } = usePagination(products, RESULTS_PER_PAGE, initialPage);
  const selectionLimit = HubLayoutConstants.PRODUCT_SELECTION_LIMIT;
  // if the products were filtered and there are fewer than 10 products, and the user was on page 2 for example
  // not switching the page to 1, would result in not rendering anything
  if (currentPage > 1 && products.length < RESULTS_PER_PAGE) {
    setPage(1);
  }
  const hasPagination = products.length > RESULTS_PER_PAGE;

  const startIndex = (currentPage - 1) * RESULTS_PER_PAGE;
  const endIndex = startIndex + RESULTS_PER_PAGE;
  const processedProducts = levels.length ? orderProductsByLevel(products, levels) : products;
  const productsToRender = processedProducts.slice(startIndex, endIndex);

  const hasOneLevel = levels.length === 1;
  const hasMultipleLevels = levels.length > 1;
  const hasNoLevels = !hasOneLevel && !hasMultipleLevels;

  useEffect(() => {
    setSelectionLimitReached(selectedProducts.length >= selectionLimit);
  }, [selectedProducts]);
  const formatLevelText = text =>
    `${text.charAt(0).toUpperCase()}${text
      .slice(1)
      .toLowerCase()
      .replace(/_/g, '-')}`;

  const toggleProducts = (productsToSelect, areAllSelected) => {
    if (areAllSelected) {
      // if they are all selected already we need to deselect all of them
      onProductClick(productsToSelect, true);
      if (selectionLimitReached) {
        setSelectionLimitReached(false);
      }
    } else {
      if (selectionLimitReached) return;
      // otherwise we select only the ones not already being selected
      let unselectedLevelProducts = [...productsToSelect];
      selectedProducts.forEach(selectedProduct => {
        unselectedLevelProducts = unselectedLevelProducts.filter(
          unselectedProduct => unselectedProduct.productid !== selectedProduct.productid
        );
      });
      if (selectedProducts.length + unselectedLevelProducts.length > selectionLimit) {
        const selectionAvailable = selectionLimit - selectedProducts.length;
        unselectedLevelProducts = unselectedLevelProducts.slice(0, selectionAvailable);
        setSelectionLimitReached(true);
      }
      onProductClick(unselectedLevelProducts, false);
    }
  };

  const areAllProductsSelected = (_products, _selectedProducts) => {
    const productsFromGroupSelected = _products.filter(_product => {
      let productIsSelected = false;
      for (let i = 0; i < _selectedProducts.length; i += 1) {
        if (_selectedProducts[i].productid === _product.productid) {
          productIsSelected = true;
          break;
        }
      }
      return productIsSelected;
    });
    return productsFromGroupSelected.length === _products.length;
  };
  const renderLevelGroup = (level, productsGroupedByLevel) => {
    const allLevelProductsSelected = areAllProductsSelected(productsGroupedByLevel, selectedProducts);
    return (
      <div key={level}>
        <div className={styles.levelGroupContainer}>
          <Checkbox
            label="Select product"
            hideLabel
            checked={allLevelProductsSelected}
            onChange={() => toggleProducts(productsGroupedByLevel, allLevelProductsSelected)}
            // only disable if its not already selected and the limit has been reached
            disabled={!allLevelProductsSelected && selectionLimitReached}
          />
          <h3 className={styles.levelGroupHeader}>{formatLevelText(level)}</h3>
        </div>
        {productsGroupedByLevel.map(product => (
          <ProductFinderProduct
            productFinderContent={productFinderContent}
            key={product.productid}
            product={product}
            onClick={(selectedProduct, isProductSelected) => toggleProducts([selectedProduct], isProductSelected)}
            selectedProducts={selectedProducts}
            selectionLimitReached={selectionLimitReached}
          />
        ))}
      </div>
    );
  };
  const renderMaterialHeader = () => {
    const areAllMaterialsSelected = areAllProductsSelected(productsToRender, selectedProducts);
    return (
      <div className={styles.levelGroupContainer}>
        <Checkbox
          label="Select all materials"
          hideLabel
          checked={areAllMaterialsSelected}
          onChange={() => toggleProducts(productsToRender, areAllMaterialsSelected)}
          // only disable if its not already selected and the limit has been reached
          disabled={!areAllMaterialsSelected && selectionLimitReached}
        />
        <h3 className={styles.levelGroupHeader}>{productFinderContent.material}</h3>
      </div>
    );
  };
  return (
    <>
      {hasNoLevels && renderMaterialHeader()}
      <ol>
        {!hasNoLevels &&
          levels.map(level => {
            const productsGroupedByLevel = productsToRender.filter(product => product['elt:level'] === level);
            return !!productsGroupedByLevel.length && renderLevelGroup(level, productsGroupedByLevel);
          })}
        {hasNoLevels &&
          productsToRender.map(product => (
            <ProductFinderProduct
              productFinderContent={productFinderContent}
              key={product.productid}
              product={product}
              onClick={(selectedProduct, isProductSelected) => toggleProducts([selectedProduct], isProductSelected)}
              selectedProducts={selectedProducts}
              selectionLimitReached={selectionLimitReached}
            />
          ))}
      </ol>
      {hasPagination ? (
        <div className={styles.textContainer}>
          <PaginationButtons
            idPrefix="productFinder"
            value={currentPage}
            numberOfPages={totalPages}
            onClick={page => {
              setSearchStateAction({ [searchType]: { page } });
              setPage(page);
            }}
          />
        </div>
      ) : null}
    </>
  );
}

ProductFinderProductResults.propTypes = {
  levels: PropTypes.array,
  productFinderContent: PropTypes.object.isRequired,
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  onProductClick: PropTypes.func.isRequired,
  selectedProducts: PropTypes.array,
  searchState: PropTypes.object.isRequired,
  setSearchStateAction: PropTypes.func.isRequired,
  searchType: PropTypes.oneOf([
    searchTypes.PRODUCTS,
    searchTypes.STUDENT_PRODUCTS_IN_SERIES,
    searchTypes.TEACHER_PRODUCTS_IN_SERIES
  ]).isRequired
};

export default connect(
  state => ({
    searchState: state.productFinder.searchState
  }),
  {
    setSearchStateAction: setSearchState
  }
)(ProductFinderProductResults);
