import { pick, get } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import Badge from '../../../components/Badge/Badge';
import ConfirmationModal from '../../../components/ConfirmationModal/ConfirmationModal';
import LinkWithIcon from '../../../components/LinkWithIcon/LinkWithIcon';
import ListPageControls from '../../../components/ListPageControls/ListPageControls';
import PopoutPanel from '../../../components/PopoutPanel/PopoutPanel';
import AssignmentListing from '../../../components/AssignmentListing/AssignmentListing';
import SearchStatus from '../../../components/SearchStatus/SearchStatus';
import SlideupPanel from '../../../components/SlideupPanel/SlideupPanel';
import { GLYPHS } from '../../../components/SVGIcon/SVGIcon';
import withDataRecency from '../../../dataRecency/withDataRecency';
import colors from '../../../globals/colors';
import { searchProductSortOptions } from '../../../globals/searchFilters';
import withLocalizedContent from '../../../language/withLocalizedContent';
import { bypassProductSelection, resetJourney } from '../../../redux/reducers/assignLearningMaterial.reducer';
import { setFilter, setPage, setSort, setTerm, triggerSearch } from '../../../redux/reducers/data/search.reducer';
import {
  resetState as resetRemoveLearningMaterialForm,
  resetStateForInstance as resetRemoveLearningMaterialFormForInstance,
  setAssignmentSelected,
  setContext,
  setMaterialToRemove,
  toggleRemoveMaterialModal
} from '../../../redux/reducers/removeLearningMaterial.reducer';
import AssignLearningMaterial from '../../panels/AssignLearningMaterial/AssignLearningMaterial';
import RemoveLearningMaterial from '../../panels/RemoveLearningMaterial/RemoveLearningMaterial';
import { safePanelLink, safePanelUnlink } from '../../../utils/links/panelLinks';
import { isHubMode } from '../../../utils/platform';
import ProductFinderPanel from '../../../components/ProductFinder/ProductFinderPanel';

class AssignmentsTab extends Component {
  componentDidMount() {
    const { resetRemoveLearningMaterialFormAction, setContextAction } = this.props;
    resetRemoveLearningMaterialFormAction();
    setContextAction('CLASS');
  }

  _handleAssignmentSelect = assignmentId => {
    const { selectedAssignmentIds, setAssignmentSelectedAction } = this.props;
    setAssignmentSelectedAction(assignmentId, !selectedAssignmentIds.includes(assignmentId));
  };

  _handleAssignLicenceClick = (productId, { assignmentId, userWithoutLicence }) => {
    const { classroomId, initialiseAssignLicence, history } = this.props;
    initialiseAssignLicence('CLASS_RENEW', classroomId, userWithoutLicence, productId, assignmentId);
    history.push(safePanelLink('assignLicence'));
  };

  _handleAssignmentsRemove = productIds => {
    const { classroomId: groupId, classAssignments, setMaterialToRemoveAction, history } = this.props;
    const assignments = productIds.map(id => ({
      ...pick(classAssignments[id], 'assignmentId'),
      productId: id,
      groupId
    }));
    setMaterialToRemoveAction(assignments);
    history.push(safePanelLink('remove'));
  };

  render() {
    const {
      canManageAssignments = true,
      currentUserCanEditClass,
      classAssignments,
      classAssignmentsDataRecency,
      error,
      filters,
      loading,
      localizedContent: { assignmentsTab: content, removeLearningMaterialModal: removeMaterialModalContent },
      orgId,
      panelName,
      resetAssignLearningMaterialFormAction,
      resetRemoveLearningMaterialFormForInstanceAction,
      resultIds,
      searchTerm,
      selectedAssignmentIds,
      setFilterAction,
      setSearchTerm,
      setSortAction,
      showRemoveMaterialModal,
      sort,
      tabName,
      toggleRemoveMaterialModalAction,
      triggerSearchAction,
      teacherIdList,
      studentIdList,
      history,
      classroomName,
      classId
    } = this.props;

    let sortedClassAssignments = {};
    if (!(loading || error) && resultIds.length > 0) {
      resultIds.forEach(assignmentId => {
        sortedClassAssignments[assignmentId] = classAssignments[assignmentId];
      });
    } else {
      sortedClassAssignments = classAssignments;
    }

    return (
      <div>
        <ListPageControls
          idPrefix="product"
          searchInputLabel={content.search_class_input_label_text}
          searchInputPlaceholder={content.assignment_tab_search_placeholder}
          searchInputValue={searchTerm}
          searchInputOnChange={setSearchTerm}
          searchInputOnSubmit={triggerSearchAction}
          filterOptions={[
            {
              text: content.filter_active_learning_material_text,
              id: 'classProductSearch-filterActive',
              name: 'classProductSearch-filterActive',
              value: 'active',
              checked: filters.active,
              onChange: setFilterAction
            },
            {
              text: content.filter_archived_learning_material_text,
              id: 'classProductSearch-filterArchived',
              name: 'classProductSearch-filterArchived',
              value: 'archived',
              checked: filters.archived,
              onChange: setFilterAction
            }
          ]}
          sortOnChange={setSortAction}
          sortOptions={searchProductSortOptions('libraryProductSearch', sort)}
          newButtonText={content.add_assignment_button_text}
          newButtonTo={currentUserCanEditClass && canManageAssignments ? safePanelLink('assignMaterial') : null}
          ariaControls="searchResults"
          loading={classAssignmentsDataRecency.syncing}
          loadingMessage={content.loading_message}
          loaded={classAssignmentsDataRecency.synced}
          loadedMessage={content.loaded_message}
          showSkeletonLoader={loading}
        />

        <SearchStatus
          searchSource="classAssignments"
          noResultsFoundContent={
            <div className="grid">
              <div className="row">
                <div className="col" id="searchResults" role="region" aria-live="polite" aria-atomic="true">
                  <p className="gin-top1 gin-bot1">{content.no_libray_items_found_text}</p>
                </div>
              </div>
            </div>
          }
        />

        {!(loading || error) && resultIds.length > 0 ? (
          <div className="grid horizantal-scroll-mobile">
            <div className="row">
              <div id="searchResults" className="col" role="region" aria-live="polite" aria-atomic="true">
                <AssignmentListing
                  heading="E-books"
                  items={sortedClassAssignments}
                  selectedItems={selectedAssignmentIds}
                  processingItems={classAssignmentsDataRecency.syncing ? classAssignmentsDataRecency.ids : []}
                  entitlement={[...teacherIdList, ...studentIdList].length}
                  selectable={currentUserCanEditClass}
                  onItemSelect={this._handleAssignmentSelect}
                  onAssignLicenceClick={this._handleAssignLicenceClick}
                  {...(currentUserCanEditClass ? { onRemoveClick: this._handleAssignmentsRemove } : {})}
                  selectAllEnabled
                  currentUserCanEditClass={currentUserCanEditClass}
                />
              </div>
            </div>
          </div>
        ) : null}

        {currentUserCanEditClass && (
          <div>
            <SlideupPanel isOpen={!!selectedAssignmentIds.length}>
              <div style={{ display: 'flex' }}>
                <div style={{ marginRight: '2rem' }}>
                  <Badge backgroundColor={colors.DASHBOARD} value={selectedAssignmentIds.length} />
                  <span style={{ marginLeft: '0.5rem', verticalAlign: 'middle' }}>
                    {content.assignment_selected_text}
                  </span>
                </div>
                <LinkWithIcon
                  id="removeMaterialsLink"
                  to={safePanelLink('remove')}
                  action={() => this._handleAssignmentsRemove(selectedAssignmentIds)}
                  text={content.remove_learning_material_text}
                  glyph={GLYPHS.ICON_RIGHT}
                />
              </div>
            </SlideupPanel>
            <PopoutPanel
              id="assignLearningMaterialPopout"
              ariaLabel={content.aria_label_assign_material_to_class}
              isOpen={tabName === 'assignments' && panelName === 'assignMaterial'}
            >
              {isHubMode() ? (
                <ProductFinderPanel
                  orgId={orgId}
                  classId={classId}
                  selectedUsers={{ teacherIdList: [...teacherIdList], studentIdList: [...studentIdList] }}
                  contextName={classroomName}
                  onClose={toggleRemoveMaterialModalAction}
                  onComplete={() => {
                    history.push(safePanelUnlink('assignMaterial'));
                  }}
                  // needed for polling. will be removed when polling is removed
                  context="CLASS"
                />
              ) : (
                <AssignLearningMaterial
                  context="CLASS"
                  orgId={orgId}
                  closePopoutAction={toggleRemoveMaterialModalAction}
                  onComplete={() => {
                    history.push(safePanelUnlink('assignMaterial'));
                  }}
                />
              )}
            </PopoutPanel>
            <PopoutPanel
              id="assignLicencesPopout"
              ariaLabel={content.aria_label_assign_licences_to_class}
              isOpen={tabName === 'assignments' && panelName === 'assignLicence'}
            >
              {isHubMode() ? (
                <ProductFinderPanel
                  orgId={orgId}
                  classId={classId}
                  selectedUsers={{ teacherIdList: [...teacherIdList], studentIdList: [...studentIdList] }}
                  contextName={classroomName}
                  onClose={toggleRemoveMaterialModalAction}
                  onComplete={() => {
                    history.push(safePanelUnlink('assignMaterial'));
                  }}
                  // needed for polling. will be removed when polling is removed
                  context="CLASS_RENEW"
                />
              ) : (
                <AssignLearningMaterial
                  context="CLASS_RENEW"
                  orgId={orgId}
                  closePopoutAction={toggleRemoveMaterialModalAction}
                  onComplete={() => {
                    history.push(safePanelUnlink('assignLicence'));
                  }}
                />
              )}
            </PopoutPanel>
            <PopoutPanel
              id="removeLearningMaterialPopout"
              ariaLabel={content.aria_label_remove_material_from_class}
              isOpen={tabName === 'assignments' && panelName === 'remove'}
              closeAction={toggleRemoveMaterialModalAction}
            >
              <RemoveLearningMaterial
                context="CLASS"
                onComplete={() => history.push(safePanelUnlink('remove'))}
                closePanel={toggleRemoveMaterialModalAction}
              />
            </PopoutPanel>
            {showRemoveMaterialModal && (
              <ConfirmationModal
                title={removeMaterialModalContent.title}
                body={removeMaterialModalContent.body}
                positiveClickText={removeMaterialModalContent.positiveClickText}
                negativeClickText={removeMaterialModalContent.negativeClickText}
                positiveClick={() => {
                  toggleRemoveMaterialModalAction();
                  setTimeout(resetRemoveLearningMaterialFormForInstanceAction, 300);
                  history.push(safePanelUnlink('remove'));
                  history.push(safePanelUnlink('assignLicence'));
                  history.push(safePanelUnlink('assignMaterial'));
                  resetAssignLearningMaterialFormAction();
                }}
                negativeClick={() => toggleRemoveMaterialModalAction()}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

AssignmentsTab.propTypes = {
  teacherIdList: PropTypes.array,
  studentIdList: PropTypes.array,
  canManageAssignments: PropTypes.bool,
  classAssignments: PropTypes.object.isRequired,
  classAssignmentsDataRecency: PropTypes.object.isRequired,
  classroomId: PropTypes.any.isRequired,
  currentUserCanEditClass: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  filters: PropTypes.object.isRequired,
  initialiseAssignLicence: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  localizedContent: PropTypes.object.isRequired,
  orgId: PropTypes.any,
  page: PropTypes.number.isRequired,
  panelName: PropTypes.string,
  products: PropTypes.object.isRequired,
  resetAssignLearningMaterialFormAction: PropTypes.func.isRequired,
  resetRemoveLearningMaterialFormAction: PropTypes.func.isRequired,
  resetRemoveLearningMaterialFormForInstanceAction: PropTypes.func.isRequired,
  resultIds: PropTypes.array.isRequired,
  searchTerm: PropTypes.string.isRequired,
  selectedAssignmentIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  setAssignmentSelectedAction: PropTypes.func.isRequired,
  setContextAction: PropTypes.func.isRequired,
  setFilterAction: PropTypes.func.isRequired,
  setMaterialToRemoveAction: PropTypes.func.isRequired,
  setPageAction: PropTypes.func.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  setSortAction: PropTypes.func.isRequired,
  showRemoveMaterialModal: PropTypes.bool.isRequired,
  sort: PropTypes.string,
  tabName: PropTypes.string,
  toggleRemoveMaterialModalAction: PropTypes.func.isRequired,
  totalResults: PropTypes.number.isRequired,
  triggerSearchAction: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  classId: PropTypes.string,
  classroomName: PropTypes.string
};

export default compose(
  withRouter,
  withLocalizedContent('assignmentsTab', 'removeLearningMaterialModal'),
  withDataRecency('classAssignments'),
  connect(
    state => {
      const { studentIdList = [], teacherIdList = [], name: classroomName } = state.classrooms.data[
        state.classroomPage.classroomId
      ];
      return {
        teacherIdList,
        studentIdList,
        classroomName,
        classId: state.classroomPage.classroomId,
        classAssignments: state.search.classAssignments.data || {},
        error: state.search.classAssignments.error,
        filters: state.search.classAssignments.filters,
        loading: state.search.classAssignments.loading,
        page: state.search.classAssignments.page,
        products: get(state, ['products', 'data'], {}),
        resultIds: state.search.classAssignments.ids,
        searchTerm: state.search.classAssignments.term,
        selectedAssignmentIds: state.removeLearningMaterial.selectedAssignmentIds,
        showRemoveMaterialModal: state.removeLearningMaterial.showRemoveMaterialModal,
        sort: state.search.classAssignments.sort,
        totalResults: state.search.classAssignments.totalResults
      };
    },
    {
      initialiseAssignLicence: bypassProductSelection,
      resetAssignLearningMaterialFormAction: resetJourney,
      resetRemoveLearningMaterialFormAction: resetRemoveLearningMaterialForm,
      resetRemoveLearningMaterialFormForInstanceAction: resetRemoveLearningMaterialFormForInstance,
      setAssignmentSelectedAction: setAssignmentSelected,
      setContextAction: setContext,
      setFilterAction: (valueName, value) => setFilter('classAssignments', valueName, value),
      setMaterialToRemoveAction: setMaterialToRemove,
      setPageAction: page => setPage('classAssignments', page),
      setSearchTerm: term => setTerm('classAssignments', term),
      setSortAction: sort => setSort('classAssignments', sort[0]),
      toggleRemoveMaterialModalAction: toggleRemoveMaterialModal,
      triggerSearchAction: triggerSearch.bind(null, 'classAssignments')
    }
  )
)(AssignmentsTab);
