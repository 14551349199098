import randomize from 'randomatic';

const PREFIXES = [
  'fish',
  'owl',
  'cat',
  'bee',
  'ant',
  'fox',
  'tree',
  'sea',
  'blue',
  'red',
  'bat',
  'bear',
  'deer',
  'dove',
  'duck',
  'fly',
  'frog'
];

/**
 * Create a new random password string.
 *
 * @return {string}
 */
export default function generatePassword() {
  const index = Math.floor(Math.random() * PREFIXES.length);
  const password = PREFIXES[index];

  return password + randomize(password.length < 4 ? '00' : '0');
}
