import * as t from '../actionTypes';

const requestStatusOptions = { isLoading: true, success: false, error: false };
const successStatusOptions = { isLoading: false, success: true, error: false };
const failureStatusOptions = { isLoading: false, success: false, error: true };

export const initialState = {
  isLoading: true,
  content: '',
  status: ''
};
export default function wordOfTheDay(state = initialState, { type, payload }) {
  switch (type) {
    case t.WORD_OF_THE_DAY_REQUEST:
      return {
        ...state,
        ...requestStatusOptions
      };
    case t.WORD_OF_THE_DAY_SUCCESS:
      return {
        ...state,
        content: payload,
        status: 'success',
        ...successStatusOptions
      };
    case t.WORD_OF_THE_DAY_ERROR:
      return { ...state, content: payload, status: 'error', ...failureStatusOptions };
    default:
      return state;
  }
}
