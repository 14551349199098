import * as t from '../actionTypes';

export const getLicenceCreditsProductsRequest = orgId => ({
  type: t.GET_LICENCE_CREDITS_PRODUCTS_REQUEST,
  payload: orgId
});

export const getLicenceCreditsProductsSucces = data => ({
  type: t.GET_LICENCE_CREDITS_PRODUCTS_SUCCESS,
  payload: data
});

export const getLicenceCreditsProductsFailure = errors => ({
  type: t.GET_LICENCE_CREDITS_PRODUCTS_FAILURE,
  payload: errors
});
