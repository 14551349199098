/* eslint-disable import/default */
/* eslint-disable import/namespace */
import React from 'react';
import { Route, BrowserRouter, Switch } from 'react-router-dom';

import LocationChangeListener from './locationChangeListener';

import userRoles from '../globals/userRoles';

import AppLayout from './AppLayout/AppLayout';
import IndexRedirectHandler from '../routes/IndexRedirectHandler';
import NotFoundView from '../views/errors/NotFoundView';

// List Pages
import MySchoolPage from '../routes/MySchool/MySchool';
import ClassroomPage from '../routes/ClassroomPage/ClassroomPage';
import MyProfilePage from '../routes/MyProfile/MyProfile';

import UserProfileInit from '../routes/UserProfile/UserProfileInitialising';

import CreateOrganisation from '../routes/CreateOrganisation/CreateOrganisation';

// Auth pages
import RegisterPage from '../routes/Register/RegisterPage';
import RegisterChoice from '../routes/RegisterChoice/RegisterChoice';
import RequestPasswordReset from '../routes/RequestPasswordReset/RequestPasswordReset';
import ResetPassword from '../routes/ResetPassword/ResetPassword';
import InvitationUpdatePage from '../routes/Register/Invites/InvitationUpdatePage';
import OfflineAppSuccessPage from '../routes/OfflineAppSuccessPage/OfflineAppSuccessPage';

// Change username
import ChangeUsernamePage from '../routes/ChangeUsername/ChangeUsernamePage';

// Support pages
import SupportHomePage from '../routes/Support/SupportHomePage';
import SupportArticlePage from '../routes/Support/SupportArticle/SupportArticlePage';
import GeneralTermsAndConditions from '../routes/GeneralTermsAndConditions/GeneralTermsAndConditions';

// Product Download Links Page
import GenerateDownloadLinksPage from '../routes/ProductPage/GenerateDownloadLinksPage.js';

// ORB pages
import ORBLayout from './ORBLayout/ORBLayout';
import OrbClassReportPage from '../routes/development/ORB/OrbClassReportPage';
import OrbTeacherResourcesPage from '../pages/OrbTeacherResourcesPage';
import OrbOfflineAppPage from '../pages/OrbOfflineAppPage';
import OrbTeacherContactPreferencesPage from '../pages/OrbTeacherContactPreferencesPage/OrbTeacherContactPreferencesPage';

// Example pages
import ExampleHomePage from '../routes/Example/ExamplePage';

// Blank page
import BlankPage from '../routes/BlankPage/BlankPage';

// OALD Redeem-code pages
import RedeemCodePage from '../routes/RedeemCode/RedeemCodePage';
import RedeemCodeSuccessPage from '../routes/RedeemCode/RedeemCodeSuccessPage';

import StudentAnswerViewPage from '../routes/Gradebook/StudentAnswerViewPage';

import PlatformRegistrationPage from '../routes/PlatformRegistration/PlatformRegistrationPage';

import protectedRoute from './protectedRoute';
import restrictByOrgUser from './restrictByOrgUser';
import withAuthorization from '../authorization/withAuthorization';
import {
  isAdminOrSupport,
  isAdminSupportOrContent,
  isELTReviewer,
  isLoggedIn
} from '../redux/selectors/authorization/user';
import { limitToOrgRoles } from '../redux/selectors/authorization/organization';
import ProductUploadPage from '../pages/dashboard/ProductUpload/ProductUploadPage';
import ProductPublishPage from '../pages/dashboard/ProductPublish/ProductPublishPage';
import GenerateReportPage from '../pages/dashboard/Reports/GenerateReportPage';
import InvoiceReportPage from '../pages/dashboard/Reports/InvoiceReportPage';
import AdminProductsPage from '../components/OrganisationAdmin/AdminProductsPage.js';
import AdminCoursesPage from '../components/OrganisationAdmin/AdminCoursesPage.js';
import ContentPreviewPage from '../pages/dashboard/content/ContentPreviewPage/ContentPreviewPage.js';
import LorContentPage from '../pages/dashboard/content/LorContentPage/LorContentPage';
import EpsContentPage from '../pages/dashboard/content/EpsContentPage/EpsContentPage';
import EpsContentArea from '../pages/dashboard/content/EpsContentArea/EpsContentArea';
import ActivationCodeContentPage from '../pages/dashboard/content/ActivationCodeContentPage/ActivationCodeContentPage.js';

import ActivationCodeSearchArea from '../pages/dashboard/content/ActivationCodeContentPage/ActivationCodeSearchArea.js';
import ActivationCodeCreateArea from '../pages/dashboard/content/ActivationCodeContentPage/ActivationCodeCreateArea.js';
import ActivationCodeDeleteArea from '../pages/dashboard/content/ActivationCodeContentPage/ActivationCodeDeleteArea.js';

import ProductGroupSearchPage from '../pages/dashboard/content/ProductGroupSearchPage/ProductGroupSearchPage.js';
import ActivationCodeDetailPage from '../pages/dashboard/content/ActivationCodeContentPage/ActivationCodeDetailPage.js';
import StructuredContentPlayerBlankPage from '../routes/BlankPage/StructuredContentPlayerBlankPage.js';
import CourseContentPreviewLayout from './CourseContentLayout/CourseContentPreviewLayout.js';
import CourseUploadPage from '../pages/dashboard/content/CourseUpload/CourseUploadPage.js';
import CoursePreviewPage from '../pages/dashboard/content/CoursePreview/CoursePreviewPage.js';
import CourseStagePage from '../pages/dashboard/content/CourseStage/CourseStagePage.js';
import CoursePublishPage from '../pages/dashboard/content/CoursePublish/CoursePublishPage.js';
import AssetsPage from '../pages/dashboard/content/AssetsPage/AssetsPage.js';
import EltCoreProductBuilder from '../pages/dashboard/content/EltCoreProductBuilder/EltCoreProductBuilder.js';
import PreviewELTCoreProduct from '../pages/dashboard/content/PreviewELTCoreProduct/PreviewELTCoreProduct.js';
import UploadProductStructure from '../pages/dashboard/content/UploadProductStructure/UploadProductStructure.js';
import ActivationCodeBatchPage from '../pages/dashboard/content/ActivationCodeBatchPage/ActivationCodeBatchPage.js';
import ProductCreatePage from '../pages/dashboard/content/ProductCreationPage/ProductCreatePage.js';
import UserAdminDashboardPage from '../pages/dashboard/content/UsersAdminDashboardPage/UserAdminDashboardPage.js';
import UserAdminSearch from '../pages/dashboard/content/UsersAdminDashboardPage/UserAdminSearch';
import PreSeedingOrganisationPage from '../pages/dashboard/PreSeedingOrganisation/PreSeedingOrganisationPage';
import EpsAdminOPS from '../pages/dashboard/EpsAdminOPS/EpsAdminOPS';
import RegisterOrgDashboardPage from '../pages/dashboard/RegisterOrg/RegisterOrgPage';
import ActCodesBatchDashboard from '../pages/dashboard/content/ActivationCodeBatchPage/components/ActCodesBatchDashboard';
import ActivationCodeBatchSearch from '../pages/dashboard/EpsAdminOPS/ActivationCodeBatchSearch/ActivationCodeBatchSearch';
import CourseMaterial from './HubProductLayout/CourseMaterial/CourseMaterial';
import ReCAPTCHAExample from '../routes/ReCAPTCHAExample/ReCAPTCHAExample';

import {
  ActivityPage,
  ManagedUserLoginPage,
  OrganisationAdminPage,
  RegisterOrgAdminPage,
  ItalyTeacherResourcesPage,
  CreateOrgSubscriptionPage,
  UpdateOrgSubscriptionPage,
  CsvUserUploadPage,
  CsvProductStructureUploadPage,
  EmailToolsPage,
  OicEmailToolsPage,
  SystemNotificationPage,
  RemoveUserLicencePage,
  OicCreateTestCreditsPage,
  OicLinkOrgsToJanisonPage,
  UserImpersonationPage,
  OrganizationsPage,
  OrganizationPage,
  RegisterOrgPage,
  LegacyRegisterOrgPage
} from '../pages';

import DownloadSigninCardProcess from '../components/DownloadSigninCardProcess/DownloadSigninCardProcess';
// show invitations
import ConnectedBannerInvitation from '../components/BannerInvitation/ConnectedBannerInvitation';

// Decline Org Invitation from Email
import DeclineOrgInvitation from '../routes/DeclineOrgInvitation/DeclineOrgInvitation';
import OrbRegionDemoPage from '../routes/development/ORB/OrbRegionDemoPage';
import OrbRegionDemoRoot from '../routes/development/ORB/OrbRegionDemoRoot';
// start up pages
import StartTips from '../routes/ORBFirstTimeVisitPages/StartTips';
// Join a class page
import JoinClassPage from '../routes/JoinClass/JoinClassPage';
// Edit Panel page for iframe
import MyProfileEditPanel from '../routes/EditAccount/MyProfileEditPanelPage';
// Redirect to Auth0
import LoginPage from '../routes/LoginPage/LoginPage';

import LaunchTokenLogoutPage from '../routes/LaunchTokenLogoutPage/LaunchTokenLogoutPage';

import SEBSignedOut from '../routes/SEB/SEBSignedOut';
import SEBSignedIn from '../routes/SEB/SEBSignedIn';
import { featureIsEnabled } from '../globals/envSettings';
import ProductAdminDashboardPage from '../pages/dashboard/content/ProductsAdminDashboardPage/ProductAdminDashboardPage.js';
import ProductSearchPage from '../pages/dashboard/content/ProductSearchPage/ProductSearchPage.js';
import EReaderContentPage from '../pages/dashboard/content/EReaderContentPage/EReaderContentPage';
import EreaderContentPlayerBlankPage from '../routes/BlankPage/EreaderContentPlayerBlankPage';
import LtiLicenceCreditsPage from '../pages/LtiLicenceCreditsPage.js';

const hasToken = () => {
  const result = window.location.pathname.match(/register.(\S*)/);
  if (result && result.length > 1 && result[1].length > 30) {
    return true;
  }

  return false;
};
// console.debug('In routeManagerEdu.js - showDevelopmentPages is set to <%s>', showDevelopmentPages);

function RouteManager() {
  const getRegisterOrgPageComponent = () =>
    featureIsEnabled('register-org-restyle') ? <RegisterOrgPage /> : <LegacyRegisterOrgPage />;

  return (
    <BrowserRouter>
      <LocationChangeListener>
        <Switch>
          {/* Blank page for loading core assets only, used for OUP.VIEW.embed prior to .gotoPage(...) */}
          <Route path="blank">
            <BlankPage />
          </Route>

          {/* Logged out status page for LTI Tokens and Impersonation tokens */}
          <Route path="/logged-out">
            <AppLayout>
              <LaunchTokenLogoutPage />
            </AppLayout>
          </Route>

          {/* redirect to Auth0 */}
          <Route path="/login">
            <LoginPage />
          </Route>

          {/* ORB Demo Pages */}
          <Route path="/orb-region">
            <OrbRegionDemoRoot>
              <OrbRegionDemoPage />
            </OrbRegionDemoRoot>
          </Route>

          <Route exact path="/">
            <AppLayout>
              <IndexRedirectHandler />
            </AppLayout>
          </Route>

          <Route path="/recaptcha-example">
            <AppLayout>{withAuthorization(ReCAPTCHAExample, isAdminSupportOrContent, '/myProfile')}</AppLayout>
          </Route>
          <Route path="/terms-and-conditions/all">
            <AppLayout>
              <GeneralTermsAndConditions />
            </AppLayout>
          </Route>

          {/* ORB routes */}
          <Route path="/dashboard/org/register">
            <AppLayout>{withAuthorization(RegisterOrgDashboardPage, isAdminOrSupport, '/myProfile')}</AppLayout>
          </Route>

          <Route path="/dashboard/org/:orgId?/licence">
            <AppLayout>{withAuthorization(CreateOrgSubscriptionPage, isAdminOrSupport, '/myProfile')}</AppLayout>
          </Route>

          <Route path="/dashboard/org/:orgId?/update-licence/:licenceId?">
            <AppLayout>{withAuthorization(UpdateOrgSubscriptionPage, isAdminOrSupport, '/myProfile')}</AppLayout>
          </Route>

          <Route path="/dashboard/org/:orgId?/test-credits">
            <AppLayout>{withAuthorization(OicCreateTestCreditsPage, isAdminOrSupport, '/myProfile')}</AppLayout>
          </Route>

          <Route path="/dashboard/org/:orgId?/licence-credits">
            <AppLayout>{withAuthorization(LtiLicenceCreditsPage, isAdminOrSupport, '/myProfile')}</AppLayout>
          </Route>

          <Route path="/dashboard/org/link-oic-orgs-to-janison">
            <AppLayout>{withAuthorization(OicLinkOrgsToJanisonPage, isAdminOrSupport, '/myProfile')}</AppLayout>
          </Route>

          <Route path="/dashboard/org/organizations">
            <AppLayout>{withAuthorization(OrganizationsPage, isAdminOrSupport, '/myProfile')}</AppLayout>
          </Route>

          <Route path="/dashboard/org/:orgId?/organizations">
            <AppLayout>{withAuthorization(OrganizationPage, isAdminOrSupport, '/myProfile')}</AppLayout>
          </Route>

          <Route path="/dashboard/org/admin/register">
            <AppLayout>{withAuthorization(RegisterOrgAdminPage, isAdminOrSupport, '/myProfile')}</AppLayout>
          </Route>

          <Route path="/dashboard/user/impersonate">
            <AppLayout>{withAuthorization(UserImpersonationPage, isAdminOrSupport, '/myProfile')}</AppLayout>
          </Route>

          <Route path="/dashboard/org/pre-seeding-organisations">
            <AppLayout>{withAuthorization(PreSeedingOrganisationPage, isAdminOrSupport, '/myProfile')}</AppLayout>
          </Route>

          <Route path="/dashboard/user/remove-licence">
            <AppLayout>{withAuthorization(RemoveUserLicencePage, isAdminOrSupport, '/myProfile')}</AppLayout>
          </Route>

          <Route path="/dashboard/csv-user-data-upload">
            <AppLayout>{withAuthorization(CsvUserUploadPage, isAdminOrSupport, '/myProfile')}</AppLayout>
          </Route>

          <Route path="/dashboard/email-tools">
            <AppLayout>{withAuthorization(EmailToolsPage, isAdminOrSupport, '/myProfile')}</AppLayout>
          </Route>

          <Route path="/dashboard/oic-email-tools">
            <AppLayout>{withAuthorization(OicEmailToolsPage, isAdminOrSupport, '/myProfile')}</AppLayout>
          </Route>

          <Route path="/dashboard/system-notification">
            <AppLayout>{withAuthorization(SystemNotificationPage, isAdminOrSupport, '/myProfile')}</AppLayout>
          </Route>

          <Route path="/dashboard">
            <AppLayout>{withAuthorization(OrganisationAdminPage, isAdminOrSupport, '/myProfile')}</AppLayout>
          </Route>

          <Route path="/products/oup-data-publish">
            <AppLayout>{withAuthorization(ProductPublishPage, isAdminSupportOrContent, '/myProfile')}</AppLayout>
          </Route>

          <Route path="/eltcore-builder/oup-data-upload">
            <AppLayout>
              <ProductUploadPage selectedPlatformCode="ELTCORE" />
            </AppLayout>
          </Route>

          <Route path="/reports/generate-report">
            <AppLayout>{withAuthorization(GenerateReportPage, isAdminSupportOrContent, '/myProfile')}</AppLayout>
          </Route>
          <Route path="/reports/invoice-report">
            <AppLayout>{withAuthorization(InvoiceReportPage, isAdminSupportOrContent, '/myProfile')}</AppLayout>
          </Route>
          <Route path="/products/oup-data-upload">
            <AppLayout>{withAuthorization(ProductUploadPage, isAdminSupportOrContent, '/myProfile')}</AppLayout>
          </Route>

          <Route path="/courses/course-preview/:courseId/resourceFolder/:folder/:subFolder">
            <AppLayout>{withAuthorization(CourseContentPreviewLayout, isELTReviewer, '/myProfile')}</AppLayout>
          </Route>

          <Route path="/courses/course-preview/:courseId/courseMaterial">
            <AppLayout>
              <CourseContentPreviewLayout>
                {withAuthorization(CourseMaterial, isELTReviewer, '/myProfile')}
              </CourseContentPreviewLayout>
            </AppLayout>
          </Route>

          <Route path="/courses/course-preview/:courseId">
            <AppLayout>{withAuthorization(CourseContentPreviewLayout, isELTReviewer, '/myProfile')}</AppLayout>
          </Route>

          <Route path="/courses/oup-data-upload">
            <AppLayout>{withAuthorization(CourseUploadPage, isAdminSupportOrContent, '/myProfile')}</AppLayout>
          </Route>

          <Route path="/courses/preview">
            <AppLayout>{withAuthorization(CoursePreviewPage, isELTReviewer, '/myProfile')}</AppLayout>
          </Route>

          <Route path="/courses/stage">
            <AppLayout>{withAuthorization(CourseStagePage, isAdminSupportOrContent, '/myProfile')}</AppLayout>
          </Route>

          <Route path="/courses/publish">
            <AppLayout>{withAuthorization(CoursePublishPage, isAdminSupportOrContent, '/myProfile')}</AppLayout>
          </Route>

          <Route path="/products/csv-product-structure-upload">
            <AppLayout>
              {withAuthorization(CsvProductStructureUploadPage, isAdminSupportOrContent, '/myProfile')}
            </AppLayout>
          </Route>

          <Route path="/products">
            <AppLayout>{withAuthorization(AdminProductsPage, isAdminSupportOrContent, '/myProfile')}</AppLayout>
          </Route>

          <Route path="/courses">
            <AppLayout>{withAuthorization(AdminCoursesPage, isELTReviewer, '/myProfile')}</AppLayout>
          </Route>

          <Route path="/eltcore-builder/preview">
            <AppLayout>{withAuthorization(PreviewELTCoreProduct, isELTReviewer, '/myProfile')}</AppLayout>
          </Route>

          <Route path="/eltcore-builder/uploadProductStructure">
            <AppLayout>{withAuthorization(UploadProductStructure, isAdminSupportOrContent, '/myProfile')}</AppLayout>
          </Route>

          <Route path="/eltcore-builder">
            <AppLayout>{withAuthorization(EltCoreProductBuilder, isELTReviewer, '/myProfile')}</AppLayout>
          </Route>

          <Route path="/oup/content/resources/lor">
            <AppLayout>{withAuthorization(LorContentPage, isAdminSupportOrContent, '/myProfile')}</AppLayout>
          </Route>

          <Route path="/oup/content/resources/eps">
            <AppLayout>{withAuthorization(EpsContentPage, isAdminSupportOrContent, '/myProfile')}</AppLayout>
          </Route>

          <Route path="/oup/content/resources/ereader">
            <AppLayout>{withAuthorization(EReaderContentPage, isAdminSupportOrContent, '/myProfile')}</AppLayout>
          </Route>

          <Route path="/dashboard_eps/act_codes">
            <AppLayout>
              {withAuthorization(
                ActivationCodeContentPage,
                state => isAdminSupportOrContent(state) && featureIsEnabled('enable-eps-admin-console'),
                '/myProfile'
              )}
            </AppLayout>
          </Route>
          <Route path="/dashboard_eps/act_code_batch">
            <AppLayout>
              {withAuthorization(
                ActCodesBatchDashboard,
                state =>
                  isAdminSupportOrContent(state) &&
                  featureIsEnabled('enable-eps-admin-console') &&
                  featureIsEnabled('enable-eps-admin-act-code-batch'),
                '/myProfile'
              )}
            </AppLayout>
          </Route>

          <Route path="/dashboard_eps/act_code_batch_create">
            <AppLayout>
              {withAuthorization(
                ActivationCodeBatchPage,
                state =>
                  isAdminSupportOrContent(state) &&
                  featureIsEnabled('enable-eps-admin-console') &&
                  featureIsEnabled('enable-eps-admin-act-code-batch'),
                '/myProfile'
              )}
            </AppLayout>
          </Route>

          <Route path="/dashboard_eps/act_code_batch_search">
            <AppLayout>
              {withAuthorization(
                ActivationCodeBatchSearch,
                state =>
                  isAdminSupportOrContent(state) &&
                  featureIsEnabled('enable-eps-admin-console') &&
                  featureIsEnabled('enable-eps-admin-act-code-batch'),
                '/myProfile'
              )}
            </AppLayout>
          </Route>

          <Route path="/dashboard_eps/act_codes_search">
            <AppLayout>
              {withAuthorization(
                ActivationCodeSearchArea,
                state =>
                  isAdminSupportOrContent(state) &&
                  featureIsEnabled('enable-eps-admin-console') &&
                  featureIsEnabled('enable-eps-admin-act-code-search'),
                '/myProfile'
              )}
            </AppLayout>
          </Route>

          <Route path="/dashboard_eps/act_codes_create">
            <AppLayout>
              {withAuthorization(
                ActivationCodeCreateArea,
                state =>
                  isAdminSupportOrContent(state) &&
                  featureIsEnabled('enable-eps-admin-console') &&
                  featureIsEnabled('enable-eps-admin-act-code-create'),
                '/myProfile'
              )}
            </AppLayout>
          </Route>

          <Route path="/dashboard_eps/act_codes_delete">
            <AppLayout>
              {withAuthorization(
                ActivationCodeDeleteArea,
                state =>
                  isAdminSupportOrContent(state) &&
                  featureIsEnabled('enable-eps-admin-console') &&
                  featureIsEnabled('enable-eps-admin-act-code-delete'),
                '/myProfile'
              )}
            </AppLayout>
          </Route>

          <Route path="/dashboard_eps/ac_detail/:actCode">
            <AppLayout>
              {withAuthorization(
                ActivationCodeDetailPage,
                state =>
                  isAdminSupportOrContent(state) &&
                  featureIsEnabled('enable-eps-admin-console') &&
                  featureIsEnabled('enable-eps-admin-act-code-detail'),
                '/myProfile'
              )}
            </AppLayout>
          </Route>

          <Route path="/dashboard_eps/product_groups">
            <AppLayout>
              {withAuthorization(
                ProductGroupSearchPage,
                state => isAdminSupportOrContent(state) && featureIsEnabled('eps-admin-product-group-search'),
                '/myProfile'
              )}
            </AppLayout>
          </Route>

          <Route path="/dashboard_eps/user_dashboard">
            <AppLayout>
              {withAuthorization(
                UserAdminDashboardPage,
                state =>
                  isAdminSupportOrContent(state) &&
                  featureIsEnabled('enable-eps-admin-console') &&
                  featureIsEnabled('eps-admin-user-console'),
                '/myProfile'
              )}
            </AppLayout>
          </Route>

          <Route path="/dashboard_eps/user_dashboard_search">
            <AppLayout>
              {withAuthorization(
                UserAdminSearch,
                state =>
                  isAdminSupportOrContent(state) &&
                  featureIsEnabled('enable-eps-admin-console') &&
                  featureIsEnabled('eps-admin-user-console') &&
                  featureIsEnabled('eps-admin-user-search'),
                '/myProfile'
              )}
            </AppLayout>
          </Route>

          <Route path="/dashboard_eps/product_dashboard">
            <AppLayout>
              {withAuthorization(
                ProductAdminDashboardPage,
                state =>
                  isAdminSupportOrContent(state) &&
                  featureIsEnabled('enable-eps-admin-console') &&
                  featureIsEnabled('enable-eps-admin-product-creation'),
                '/myProfile'
              )}
            </AppLayout>
          </Route>

          <Route path="/dashboard_eps/product_dashboard_create">
            <AppLayout>
              {withAuthorization(
                ProductCreatePage,
                state =>
                  isAdminSupportOrContent(state) &&
                  featureIsEnabled('enable-eps-admin-console') &&
                  featureIsEnabled('enable-eps-admin-product-creation'),
                '/myProfile'
              )}
            </AppLayout>
          </Route>

          <Route path="/dashboard_eps/product_dashboard_search">
            <AppLayout>
              {withAuthorization(
                ProductSearchPage,
                state =>
                  isAdminSupportOrContent(state) &&
                  featureIsEnabled('enable-eps-admin-console') &&
                  featureIsEnabled('enable-eps-admin-product-creation'),
                '/myProfile'
              )}
            </AppLayout>
          </Route>

          <Route path="/dashboard_eps/eps_ops">
            <AppLayout>
              {withAuthorization(
                EpsAdminOPS,
                state => isAdminSupportOrContent(state) && featureIsEnabled('enable-eps-admin-console'),
                '/myProfile'
              )}
            </AppLayout>
          </Route>

          <Route path="/oup/content/resources/eps-content/area/products">
            <AppLayout>{withAuthorization(EpsContentArea, isAdminSupportOrContent, '/myProfile')}</AppLayout>
          </Route>

          <Route path="/oup/content/resources/navigator/:contentCode">
            <AppLayout>{withAuthorization(StructuredContentPlayerBlankPage, isELTReviewer, '/myProfile')}</AppLayout>
          </Route>

          <Route path="/launch/:role/:contentCode">
            <AppLayout>{withAuthorization(StructuredContentPlayerBlankPage, isELTReviewer, '/myProfile')}</AppLayout>
          </Route>

          <Route path="/launch-ereader/book/:book/theme/:theme">
            <AppLayout>{withAuthorization(EreaderContentPlayerBlankPage, isELTReviewer, '/myProfile')}</AppLayout>
          </Route>

          <Route path="/oup/content/resources">
            <AppLayout>{withAuthorization(ContentPreviewPage, isAdminSupportOrContent, '/myProfile')}</AppLayout>
          </Route>

          <Route path="/oup/content/assets">
            <AppLayout>{withAuthorization(AssetsPage, isAdminSupportOrContent, '/myProfile')}</AppLayout>
          </Route>

          <Route path="/org/register">
            <AppLayout>{restrictByOrgUser(getRegisterOrgPageComponent)}</AppLayout>
          </Route>

          <Route path="/org/:orgId/complete-registration">
            <AppLayout>
              <LegacyRegisterOrgPage />
            </AppLayout>
          </Route>

          <Route path="/org/:orgId/activity">
            <AppLayout>
              <ActivityPage />
            </AppLayout>
          </Route>

          <Route path="/auth/managedUser">
            <AppLayout>
              <ManagedUserLoginPage />
            </AppLayout>
          </Route>

          <Route path="/org/:orgId?/school">
            <AppLayout embedded>
              <OrbClassReportPage />
            </AppLayout>
          </Route>

          <Route path="/org/:orgId?/classroom/:classroomId?/:tabName?/:objectId?">
            <AppLayout embedded>
              <OrbClassReportPage />
            </AppLayout>
          </Route>

          <Route path="/teacherHome/reporting/:orgId?/:tabName?/:objectId?">
            <AppLayout>
              <ORBLayout>
                <OrbClassReportPage />
              </ORBLayout>
            </AppLayout>
          </Route>

          <Route path="/teacherHome/reporting/:orgId?/classroom/:classroomId/:tabName/:objectId">
            <AppLayout>
              <ORBLayout>
                <OrbClassReportPage />
              </ORBLayout>
            </AppLayout>
          </Route>

          <Route path="/teacherHome/library">
            <AppLayout>
              <ORBLayout>
                <OrbTeacherResourcesPage />
              </ORBLayout>
            </AppLayout>
          </Route>

          <Route path="/teacherHome/offlineApp">
            <AppLayout>
              <ORBLayout>
                <OrbOfflineAppPage />
              </ORBLayout>
            </AppLayout>
          </Route>

          <Route path="/teacherHome/contactPreferences">
            <AppLayout>
              <ORBLayout>
                <OrbTeacherContactPreferencesPage />
              </ORBLayout>
            </AppLayout>
          </Route>

          <Route path="/orb/teacher/reports/:orgId?/classroom/:classroomId?/:tabName?/:objectId?">
            <AppLayout embedded>
              <OrbClassReportPage />
            </AppLayout>
          </Route>

          <Route path="/orb/teacher/reports/:orgId?/:tabName?/:objectId?">
            <AppLayout embedded>
              <OrbClassReportPage />
            </AppLayout>
          </Route>

          <Route path="/register/:token?">
            <AppLayout>
              {protectedRoute(RegisterPage, false, Object.keys(userRoles), '/myProfile', false, hasToken())}
            </AppLayout>
          </Route>

          <Route path="/register-choice">
            <AppLayout>
              <RegisterChoice />
            </AppLayout>
          </Route>

          <Route path="/offlineApp/:actionType">
            <AppLayout>
              <OfflineAppSuccessPage />
            </AppLayout>
          </Route>

          <Route path="/invitation">
            <AppLayout>
              <InvitationUpdatePage />
            </AppLayout>
          </Route>

          {/* The token param can be one of:
          - Blank string for Full Registration.
          - 'finish' for completing a Partial Registration of existing or legacy user.
          - Any userToken string for completing a Partial Registration via an email link with a token. */}
          <Route path="/reset-password/:token">
            <AppLayout>
              <ResetPassword />
            </AppLayout>
          </Route>

          <Route path="/reset-password">
            <AppLayout>
              <RequestPasswordReset />
            </AppLayout>
          </Route>

          <Route path="/change-username">
            <AppLayout>
              <ChangeUsernamePage />
            </AppLayout>
          </Route>

          {/* Org page url for most people will be /mySchool but for admins it will be /org/xxx */}
          <Route path="/mySchool/:tabName?/:panelName?">
            <AppLayout>
              {protectedRoute(
                MySchoolPage,
                false,
                [userRoles.USER, userRoles.LEARNER],
                '/myProfile',
                false,
                false,
                'MY_ORG'
              )}
            </AppLayout>
          </Route>

          <Route path="/org/:orgId/user/:userId/:tabName?/:panelName?">
            <AppLayout>
              {protectedRoute(UserProfileInit, false, [userRoles.USER, userRoles.LEARNER], '/myProfile')}
            </AppLayout>
          </Route>

          {/* Download Signin Card Process */}
          <Route path="/org/:orgId/batchId/:batchId/records/:records">
            <AppLayout>
              <DownloadSigninCardProcess />
            </AppLayout>
          </Route>

          <Route path="/org/:orgId/class/:classroomId/:tabName?/:panelName?/:itemId?/:learnerId?">
            <AppLayout>
              {protectedRoute(ClassroomPage, false, [userRoles.USER, userRoles.LEARNER], '/myProfile')}
            </AppLayout>
          </Route>

          <Route path="/org/:orgId/:tabName?/:panelName?">
            <AppLayout>{withAuthorization(MySchoolPage, limitToOrgRoles(), '/')}</AppLayout>
          </Route>

          <Route path="/myProfile/:tabName?/:panelName?/:itemId?">
            <AppLayout>
              <MyProfilePage />
            </AppLayout>
          </Route>

          {/* Create Org Page */}
          <Route path="/createOrganisation">
            <AppLayout>
              <CreateOrganisation />
            </AppLayout>
          </Route>

          {/* Support pages */}
          <Route path="/support/articles/:pageName?">
            <AppLayout>
              <SupportArticlePage />
            </AppLayout>
          </Route>

          <Route path="/support/:tabName?">
            <AppLayout>
              <SupportHomePage />
            </AppLayout>
          </Route>
          {/* /:platform/:token? */}
          <Route path="/download/:platform/:token?">
            <AppLayout>
              <GenerateDownloadLinksPage />
            </AppLayout>
          </Route>

          {/* Italy Teacher Resource Panel */}
          <Route path="/premium/resources/:titleId">
            <AppLayout embedded>
              <ItalyTeacherResourcesPage />
            </AppLayout>
          </Route>

          {/* Example page */}
          <Route path="/example/:tabName?">
            <ExampleHomePage />
          </Route>

          <Route path="/redeem/code">
            <AppLayout>
              <RedeemCodeSuccessPage />
            </AppLayout>
          </Route>

          <Route path="/redeem">
            <AppLayout>
              {protectedRoute(RedeemCodePage, false, Object.keys(userRoles), '/myProfile/learningMaterial/addCode')}
            </AppLayout>
          </Route>

          {/* Decline Org Invitation */}
          <Route path="/decline-invite/:token">
            <AppLayout>
              <DeclineOrgInvitation />
            </AppLayout>
          </Route>

          {/* Show Invitations */}
          <Route path="/showInvitations">
            <AppLayout>
              <ConnectedBannerInvitation />
            </AppLayout>
          </Route>

          <Route path="/studentAnswerView/orgId/:orgId/class/:classroomId/:itemId/:learnerId/:bid/:uId/:activityId/:parentUId?">
            <AppLayout>
              <StudentAnswerViewPage />
            </AppLayout>
          </Route>

          <Route path="/orb-start-tips/:step?">
            <AppLayout>
              <StartTips />
            </AppLayout>
          </Route>

          <Route path="/join-class">
            <AppLayout>{protectedRoute(JoinClassPage, false, [userRoles.LEARNER], '/join-class')}</AppLayout>
          </Route>

          <Route path="/edit-account">
            <AppLayout>{protectedRoute(MyProfileEditPanel, false, Object.keys(userRoles), '/edit-account')}</AppLayout>
          </Route>

          <Route path="/seb-login">
            <AppLayout>
              {protectedRoute(SEBSignedOut, false, Object.keys(userRoles), '/seb-download', false, hasToken())}
            </AppLayout>
          </Route>

          <Route path="/seb-download">
            <AppLayout>
              <SEBSignedIn />
            </AppLayout>
          </Route>

          <Route path="/platform-registration/:platform">
            <AppLayout>
              {withAuthorization(
                PlatformRegistrationPage,
                () => isLoggedIn && featureIsEnabled('otc-registration'),
                '/platform-registration'
              )}
            </AppLayout>
          </Route>

          {/* 404 */}
          <Route path="*">
            <AppLayout>
              <NotFoundView />
            </AppLayout>
          </Route>

          {/* Deprecated NGS Pages - On hold - Will require updating to RR 5.x to work again */}
          {/* <Route path="ngs" component={SmartIndexRedirectHandler} />
          <Route component={NGSRoot}>
            <Route path="ngs-demo" component={NGSDemoLoginPage} />

            <Route path="ngs/invitation" context="NGS" component={InvitationUpdatePage} />
            <Route
              path="ngs/myProfile(/:tabName(/:panelName(/:itemId)))"
              component={withAuthorization(isAtLeastManagedUser, '/')(MyProfilePage)}
            />
            <Route
              path="ngs/mySchool(/:tabName(/:panelName))"
              context="MY_ORG"
              component={withAuthorization(isAtLeastTeacher, '/')(MySchoolPage)}
            />

            <Route path="ngs/org/:orgId/activity" component={ActivityPage} />

            <Route
              path="ngs/teacher/assignments"
              component={withAuthorization(isAtLeastTeacher, '/ngs')(NGSTeacherAssignmentsPage)}
            />
            <Route
              path="ngs/teacher/reports"
              component={withAuthorization(isAtLeastTeacher, '/ngs')(NGSTeacherReportsPage)}
            />
            <Route
              path="ngs/student/homework"
              component={withAuthorization(isManagedUserOrLearner, '/ngs')(NGSStudentHomeworkPage)}
            />
            <Route
              path="ngs/student/marks"
              component={withAuthorization(isManagedUserOrLearner, '/ngs')(NGSStudentMarksPage)}
            />
            <Route path="ngs/courses" component={NGSCourseLayout}>
              <Route path=":id" component={withAuthorization(isAtLeastManagedUser, '/')(NGSCourseHomePage)} />
              <Route
                path=":id/resources"
                component={withAuthorization(isAtLeastManagedUser, '/')(NGSCourseResourcesPage)}
              />

              <Route path=":id/reports" component={withAuthorization(isAtLeastTeacher, '/')(NGSCourseReportsPage)} />
              <Route
                path=":id/homework"
                component={withAuthorization(isManagedUserOrLearner, '/')(NGSCourseHomeworkPage)}
              />
              <Route path=":id/marks" component={withAuthorization(isManagedUserOrLearner, '/')(NGSCourseMarksPage)} />

              <Route path=":id/books/:bookId" component={NGSCourseBookPage} />
              <Route path=":id/resources/:resourceId" component={NGSCourseResourcePage} />
            </Route>
          </Route> */}
        </Switch>
      </LocationChangeListener>
    </BrowserRouter>
  );
}

export default RouteManager;
