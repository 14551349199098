import { put } from 'redux-saga/effects';
import { loadPendingActionsTime } from '../../../../actions/offlineContentPlayer';

function* setOfflinePendingActionsTime({ userId, currentTime }) {
  const storedValue = localStorage.getItem('offline_pending_actions_time');
  const pendingActionsTime = storedValue ? JSON.parse(storedValue) : {};

  pendingActionsTime[userId] = currentTime;
  localStorage.setItem('offline_pending_actions_time', JSON.stringify(pendingActionsTime));

  yield put(loadPendingActionsTime(pendingActionsTime));
}

export default setOfflinePendingActionsTime;
