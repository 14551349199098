import { put, takeEvery } from 'redux-saga/effects';
import * as t from '../../../../actionTypes';
import actions from '../../../../actions';
import checkLicenceApi from '../../../apiCalls/checkLicenceApi';
import checkVstLicenceApi from '../../../apiCalls/checkVstLicenceApi';
import { getCurrentPlatform } from '../../../../../utils/platform';
import getLocalStorageTempClientId from '../../../utils/clientId';

function* validateAccessCode({ accessCode, reCAPTCHAToken, source = 'EPS' }) {
  const platform = getCurrentPlatform();

  const apiResults = yield (source === 'VST' ? checkVstLicenceApi : checkLicenceApi)({
    body: {
      activationCode: accessCode,
      reCAPTCHAToken,
      reCAPTCHATokenSource: platform
    },
    headers: { tempClientId: getLocalStorageTempClientId(platform) }
  });

  if (apiResults.error || apiResults.status !== 'success') {
    const errorMessageData = {};
    const errorCode = apiResults.message.match(/\d+/);

    if (errorCode) {
      errorMessageData.code = errorCode[0];
      const codeActivationDate = apiResults.message.match(/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/);
      if (codeActivationDate) {
        errorMessageData.activationDate = codeActivationDate[0];
      }
    }
    if (apiResults.code) {
      errorMessageData.code = apiResults.code;
    }

    yield put(actions.validateAccessCodeRequestFailure({ error: errorMessageData }));
  } else {
    yield put(actions.validateAccessCodeRequestSuccess());
  }
}

export default function* index() {
  yield takeEvery(t.VALIDATE_ACCESS_CODE_REQUEST, ({ data }) => validateAccessCode(data));
}
