import React from 'react';
import PropTypes from 'prop-types';

import ROLES, { userRoleFriendlyNames } from '../../globals/userRoles';
import content from '../../utils/cmsContent';
import parseMarkdown from '../../utils/markdown/markdown';
import TextInputLabel from '../TextInputLabel/TextInputLabel';
import TextInputField from '../TextInputField/TextInputField';
import UserAgreement from '../UserAgreement/UserAgreement';
import { validationStatusPropType } from '../../types/propTypeDefinitions';

/**
 * A text input label and field.
 */
function TextInput({
  id,
  name,
  label,
  ariaLabel,
  placeholder,
  infoText,
  value,
  title,
  onChange,
  onClick,
  onBlur,
  onPaste,
  type,
  disableAutoComplete,
  disabled,
  readOnly,
  readOnlyPreserveStyle,
  required,
  maxLength,
  textArea,
  aria,
  autoFocus,
  autoHighlight,
  labelHidden,
  min,
  max,
  onFocus,
  dataTestId = '',
  validationStatus
}) {
  return (
    <div>
      {label && <TextInputLabel id={id} ariaLabel={ariaLabel} label={label} labelHidden={labelHidden} />}
      <TextInputField
        id={id}
        name={name}
        placeholder={placeholder}
        infoText={infoText}
        value={value}
        title={title}
        onChange={onChange}
        onClick={onClick}
        onBlur={onBlur}
        onFocus={onFocus}
        onPaste={onPaste}
        type={type}
        disableAutoComplete={disableAutoComplete}
        disabled={disabled}
        readOnly={readOnly}
        readOnlyPreserveStyle={readOnlyPreserveStyle}
        required={required}
        maxLength={maxLength}
        textArea={textArea}
        aria={aria}
        autoFocus={autoFocus}
        autoHighlight={autoHighlight}
        min={min}
        max={max}
        data-testid={dataTestId}
        ariaLabel={ariaLabel}
        validationStatus={validationStatus}
      />
    </div>
  );
}

TextInput.propTypes = {
  /** the id of the input field needs to be unique */
  id: PropTypes.string.isRequired,
  /** the name of the input field needs to be unique */
  name: PropTypes.string,
  /** the inputs text label */
  label: PropTypes.string,
  /** the caption for the input field */
  infoText: PropTypes?.string,
  /** the inputs placeholder text */
  placeholder: PropTypes.string,
  /** Actual value of the input. If not preset then the placeholder is shown */
  value: PropTypes.any,
  /** The optional tooltip */
  title: PropTypes.string,
  /** function that if the input is touched, will be called */
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  /** Function to bind to the native input onBlur event */
  onBlur: PropTypes.func,
  /** Function called when user pastes content into the field */
  onPaste: PropTypes.func,
  /** The type attribute of the <input> element. Restricted to text looking inputs (ie not checkbox etc). */
  type: PropTypes.oneOf(['text', 'password', 'number', 'date', 'email']),
  /** Set this to true to disable the browser's native autoComplete suggestions */
  disableAutoComplete: PropTypes.bool,
  /** Set this to true to disable any interaction on the input field */
  disabled: PropTypes.bool,
  /** Set whether the field is for presentation and is not editable. */
  readOnly: PropTypes.bool,
  readOnlyPreserveStyle: PropTypes.bool,
  /** Set this to false to prevent textbox from defaulting to required, for screenreaders etc */
  required: PropTypes.bool,
  /** Max Length value for the input element */
  maxLength: PropTypes.number,
  /* Min/Max limits for both number and date fields */
  min: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  max: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  /** Make the text box of type text area */
  textArea: PropTypes.bool,
  /** map of aria attribute names and values, eg: aria={{ role:'textbox', live: 'assertive' }} */
  aria: PropTypes.object,
  autoFocus: PropTypes.bool,
  autoHighlight: PropTypes.bool,
  /** The input label can be hidden to users and only visible to screen readers */
  labelHidden: PropTypes.bool,
  onFocus: PropTypes.func,
  dataTestId: PropTypes.string,
  ariaLabel: PropTypes.string,
  validationStatus: validationStatusPropType
};

export default TextInput;

// Common field defaults:
// Usage:
//   <TextInput {...TextInput.defaultsFor().userName} />
//
TextInput.defaultsFor = () => {
  const CMS = content.textInputComponent || {};
  const SHARED_CMS = content.cesShared || {};

  return {
    firstName: {
      id: 'firstName',
      label: `${CMS.first_name_label}:`,
      placeholder: CMS.first_name_placeholder
    },

    lastName: {
      id: 'lastName',
      label: `${CMS.last_name_label}:`,
      placeholder: CMS.last_name_placeholder
    },

    userName: {
      id: 'userName',
      type: 'email',
      label: `${CMS.username_label}:`,
      placeholder: CMS.username_placeholder,
      tip: parseMarkdown(CMS.username_tip_markdown)
    },

    email: {
      id: 'email',
      type: 'email',
      label: `${CMS.email_label}:`,
      placeholder: CMS.email_placeholder,
      tip: parseMarkdown(CMS.email_tip_markdown)
    },

    emailConfirm: {
      id: 'emailConfirmation',
      label: `${CMS.confirm_email_label}:`,
      type: 'email',
      placeholder: CMS.confirm_email_placeholder
    },

    addClassName: {
      id: 'addClassName',
      type: 'text',
      label: `${CMS.input_enter_new_class_name_label}:`,
      placeholder: `${CMS.input_enter_new_class_name_placeholder}`
    },

    // Role field is typically a Dropdown:
    userRole: {
      id: 'userRole',
      label: `${CMS.role_label}:`,
      placeholder: CMS.role_placeholder,
      options: Object.keys(ROLES).map(key => ({
        value: key,
        text: userRoleFriendlyNames(key) || ROLES[key]
      }))
    },

    role: {
      id: 'role',
      label: `${CMS.roles_label}:`,
      type: 'role',
      placeholder: CMS.roles_placeholder
    },

    school: {
      id: 'school',
      label: `${CMS.school_label}:`,
      type: 'role',
      placeholder: CMS.school_placeholder
    },

    country: {
      id: 'country',
      label: `${CMS.country_label}:`,
      type: 'role',
      placeholder: CMS.country_default_option
    },

    password: {
      id: 'password',
      label: `${CMS.password_label}:`,
      type: 'password',
      placeholder: CMS.password_placeholder,
      tip: CMS.password_tip
    },

    passwordConfirm: {
      id: 'passwordConfirmation',
      label: `${CMS.confirm_password_label}:`,
      type: 'password',
      placeholder: CMS.confirm_password_placeholder
    },

    oldPassword: {
      id: 'oldPassword',
      label: `${CMS.current_password_label}:`,
      type: 'password',
      placeholder: CMS.current_password_placeholder
    },

    termsAndConditions: {
      groupLabel: CMS.terms_and_conditions_label,
      id: 'termsAndConditions',
      label: <UserAgreement termsAndConditions />
    },
    /* eslint react/no-danger: off */
    privacyPolicy: {
      id: 'privacyPolicy',
      label: (
        <div
          dangerouslySetInnerHTML={{
            __html: parseMarkdown(
              `${SHARED_CMS.privacy_policy_info_markdown}\n\n${SHARED_CMS.personal_information_usage_markdown}`
            )
              // Need to cause the link to open in a new page
              .replace(/<a/g, '<a target="_blank" rel="noopener noreferrer"')
          }}
        />
      )
    }
  };
};
