import * as t from '../actionTypes';

export const validateAccessCodeRequest = ({ accessCode, reCAPTCHAToken, source }) => ({
  type: t.VALIDATE_ACCESS_CODE_REQUEST,
  data: { accessCode, reCAPTCHAToken, source }
});

export const validateAccessCodeRequestSuccess = () => ({
  type: t.VALIDATE_ACCESS_CODE_SUCCESS
});

export const validateAccessCodeRequestFailure = ({ error }) => ({
  type: t.VALIDATE_ACCESS_CODE_FAILURE,
  data: { error }
});

export const validateAccessCodeRequestReset = () => ({
  type: t.VALIDATE_ACCESS_CODE_RESET
});
