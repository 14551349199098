import fetchClearApi from '../../../apiCalls/ocp/clearOfflinePendingActions';

function* clearOfflinePendingActions(params) {
  const response = yield fetchClearApi(params);
  if (response.status === 'success') {
    console.log(response);
  } else {
    console.error(response);
  }
}

export default clearOfflinePendingActions;
