// Initialise action
export const INITIALISE_JOURNEY = 'assignLearningMaterial/INITIALISE_JOURNEY';
export const BYPASS_PRODUCT_SELECTION = 'assignLearningMaterial/BYPASS_PRODUCT_SELECTION';

// Product Search Actions
export const SET_SEARCH_TERM = 'assignLearningMaterial/SET_SEARCH_TERM';
export const SET_SEARCH_FILTER = 'assignLearningMaterial/SET_SEARCH_FILTER';
export const SET_SEARCH_RESULTS = 'assignLearningMaterial/SET_SEARCH_RESULTS';
export const SET_SEARCH_PAGE = 'assignLearningMaterial/SET_SEARCH_PAGE';
export const SET_PRODUCTID_SELECTED = 'assignLearningMaterial/SET_PRODUCTID_SELECTED';
export const SELECT_PRODUCT = 'assignLearningMaterial/SELECT_PRODUCT';

const TOGGLE_DETAILS_POPUP_ID = 'assignLearningMaterial/TOGGLE_DETAILS_POPUP_ID';

// Licence Info actions
export const LOAD_LICENCE_DATA = 'assignLearningMaterial/LOAD_LICENCE_DATA';
export const STORE_LICENCE_DATA = 'assignLearningMaterial/STORE_LICENCE_DATA';
export const SET_TEACHER_ASSIGN_LICENCES = 'assignLearningMaterial/SET_TEACHER_ASSIGN_LICENCES';
const LICENCE_DATA_LOADED = 'assignLearningMaterial/LICENCE_DATA_LOADED';
const LICENCE_DATA_LOADED_MULTIPLE = 'assignLearningMaterial/LICENCE_DATA_LOADED_MULTIPLE';
const LICENCE_DATA_FAILED = 'assignLearningMaterial/LICENCE_DATA_FAILED';
const SET_ASSIGN_LICENCES = 'assignLearningMaterial/SET_ASSIGN_LICENCES';

// Navigation Actions
export const BACK_TO_SEARCH = 'assignLearningMaterial/BACK_TO_SEARCH';
const GOTO_REVIEW_ASSIGNMENT = 'assignLearningMaterial/GOTO_REVIEW_ASSIGNMENT';
const BACK_TO_REVIEW_LICENCES = 'assignLearningMaterial/BACK_TO_REVIEW_LICENCES';
export const GO_TO_REVIEW_LICENCES = 'assignLearningMaterial/GO_TO_REVIEW_LICENCES';

// Submission Actions
export const SUBMIT_ASSIGNMENT = 'assignLearningMaterial/SUBMIT_ASSIGNMENT';
export const SHOW_CONFIRMATION = 'assignLearningMaterial/SHOW_CONFIRMATION';

// Reset Action
export const RESET_JOURNEY = 'assignLearningMaterial/RESET_JOURNEY';

export const formStates = {
  SEARCHING: 'SEARCHING',
  SERIES_OVERVIEW: 'SERIES_OVERVIEW',
  REVIEW_LICENCES: 'REVIEW_LICENCES',
  REVIEWING: 'REVIEWING',
  SUBMITTING: 'SUBMITTING',
  CONFIRMATION: 'CONFIRMATION'
};

export const initialState = {
  formState: formStates.SEARCHING,

  context: null,

  // Target user info
  groupType: null, // ORGANISATION or CLASS
  groupId: null,
  userIds: null,
  existingAssignmentId: null,

  // Product Search
  searchTerm: '',
  searchFilter: 'ALL',
  searchResults: [],
  totalResults: 0,
  currentPage: 1,

  // Product Info
  productId: null,

  // Details popup
  detailsOpen: false,
  popupclose: false,
  lozengeName: [],
  linkedListParentId: null,
  isLinkedList: false,

  // Licence Info
  assignLicences: false, // User controlled option to assign licences from pool
  assignLicencesMultiple: {},
  loadingLicences: false,
  loadingLicencesFailed: false,
  licencesAvailable: null,
  licencesTypes: [],
  licenceData: {},
  selectedProductIds: [],

  errorOccurred: null,
  teacherAssignLicences: false,
  teacherAssignLicencesMultiple: {}
};

function addOrRemove(selectedIds, id, isSelected) {
  return isSelected ? [...selectedIds, id] : selectedIds.filter(oldId => oldId !== id);
}

export default function assignLearningMaterial(state = initialState, action) {
  switch (action.type) {
    // Initialise Action
    case INITIALISE_JOURNEY:
      return {
        ...state,
        context: action.context
      };

    case BYPASS_PRODUCT_SELECTION:
      return {
        ...state,
        context: action.context,
        loadingLicences: true,
        formState: formStates.REVIEW_LICENCES,
        groupId: action.groupId,
        userIds: action.userIds,
        productId: action.productId,
        existingAssignmentId: action.existingAssignmentId,
        selectedProductIds: [action.productId]
      };

    // Product Search Actions
    case SET_SEARCH_TERM:
      return {
        ...state,
        searchTerm: action.searchTerm
      };
    case SET_SEARCH_FILTER:
      return {
        ...state,
        searchFilter: action.searchFilter
      };
    case SET_SEARCH_RESULTS:
      return {
        ...state,
        searchResults: action.searchResults,
        totalResults: action.totalResults
      };
    case SET_SEARCH_PAGE:
      return {
        ...state,
        currentPage: action.currentPage
      };
    case SELECT_PRODUCT:
      return {
        ...state,
        formState: formStates.REVIEW_LICENCES,
        productId: action.productId,
        loadingLicences: true
      };

    case SET_PRODUCTID_SELECTED:
      return {
        ...state,
        selectedProductIds: addOrRemove(state.selectedProductIds, action.productId, action.isSelected)
      };

    case TOGGLE_DETAILS_POPUP_ID:
      return {
        ...state,
        detailsOpen: !state.detailsOpen,
        productId: action.productId || state.productId,
        popupclose: !state.popupclose,
        lozengeName: action.lozengeName,
        linkedListParentId: action.linkedListParentId,
        context: action.context
      };

    case LOAD_LICENCE_DATA:
      return {
        ...state,
        loadingLicences: true,
        loadingLicencesFailed: false
      };

    case STORE_LICENCE_DATA:
      return {
        ...state,
        licenceData: action.licenceData
      };

    case LICENCE_DATA_FAILED:
      return {
        ...state,
        loadingLicences: false,
        loadingLicencesFailed: true
      };
    case LICENCE_DATA_LOADED_MULTIPLE:
      return {
        ...state,
        loadingLicences: false,
        assignLicencesMultiple: action.licencesAvailable,
        teacherAssignLicencesMultiple: action.teacherAssignLicencesMultiple,
        licencesAvailable: action.licencesAvailable,
        licencesTypes: action.licencesTypes,
        userIdsInOrder: action.userIdsInOrder,
        proposedAssignments: action.proposedAssignments,
        usersIdsWithLicences: action.usersIdsWithLicences,
        usersExistingLicenceDetails: action.usersExistingLicenceDetails,
        usersWithoutAssignments: action.usersWithoutAssignments,
        userIdsWithoutPermission: action.userIdsWithoutPermission,
        teacherIdsInOrder: action.teacherIdsInOrder,
        learnerUserIdsInOrder: action.learnerUserIdsInOrder,
        licencesAssignDetails: action.licencesAssignDetails,
        isVisibleWhoIsGettingLicence: action.isVisibleWhoIsGettingLicence
      };
    case LICENCE_DATA_LOADED:
      return {
        ...state,
        loadingLicences: false,
        assignLicences: action.licencesAvailable,
        licencesAvailable: action.licencesAvailable,
        licencesTypes: action.licencesTypes,
        userIdsInOrder: action.userIdsInOrder,
        proposedAssignments: action.proposedAssignments,
        usersIdsWithLicences: action.usersIdsWithLicences,
        usersExistingLicenceDetails: action.usersExistingLicenceDetails,
        usersWithoutAssignments: action.usersWithoutAssignments,
        userIdsWithoutPermission: action.userIdsWithoutPermission,
        teacherIdsInOrder: action.teacherIdsInOrder,
        learnerUserIdsInOrder: action.learnerUserIdsInOrder
      };
    case SET_ASSIGN_LICENCES:
      return {
        ...state,
        assignLicences: action.assignLicences,
        assignLicencesMultiple: { ...state.assignLicencesMultiple, [action.productId]: action.assignLicences }
      };
    case SET_TEACHER_ASSIGN_LICENCES:
      return {
        ...state,
        teacherAssignLicences: action.teacherAssignLicences,
        teacherAssignLicencesMultiple: {
          ...state.teacherAssignLicencesMultiple,
          [action.productId]: {
            teacherAssignLicences: action.teacherAssignLicences,
            notEnoughLicenceForTeacher: action.notEnoughLicenceForTeacher
          }
        }
      };

    // Navigation Actions
    case BACK_TO_SEARCH:
      return {
        ...state,
        formState: formStates.SEARCHING,
        teacherAssignLicences: false,
        productId: null
      };
    case GOTO_REVIEW_ASSIGNMENT:
      return {
        ...state,
        formState: formStates.REVIEWING
      };
    case GO_TO_REVIEW_LICENCES:
      return {
        ...state,
        formState: formStates.REVIEW_LICENCES,
        loadingLicences: true
      };
    case BACK_TO_REVIEW_LICENCES:
      return {
        ...state,
        formState: formStates.REVIEW_LICENCES
      };

    // Submission Actions
    case SUBMIT_ASSIGNMENT:
      return {
        ...state,
        formState: formStates.SUBMITTING
      };
    case SHOW_CONFIRMATION:
      return {
        ...state,
        formState: formStates.CONFIRMATION,
        errorOccurred: action.errorOccurred
      };

    // Reset Action
    case RESET_JOURNEY:
      return {
        ...initialState,
        context: state.context
      };
    default:
      return state;
  }
}

// Initialise Action
export const initialiseJourney = context => ({
  type: INITIALISE_JOURNEY,
  context
});

export const bypassProductSelection = (context, groupId, userIds, productId, existingAssignmentId) => ({
  type: BYPASS_PRODUCT_SELECTION,
  context,
  groupId,
  userIds,
  productId,
  existingAssignmentId
});

// Product Search Actions
export const setSearchTerm = searchTerm => ({
  type: SET_SEARCH_TERM,
  searchTerm
});
export const setSearchFilter = searchFilter => ({
  type: SET_SEARCH_FILTER,
  searchFilter
});
export const setSearchResults = (searchResults, totalResults) => ({
  type: SET_SEARCH_RESULTS,
  searchResults,
  totalResults
});
export const setSearchPage = currentPage => ({
  type: SET_SEARCH_PAGE,
  currentPage
});
export const selectProduct = productId => ({
  type: SELECT_PRODUCT,
  productId
});
export const setProductSelected = (productId, isSelected) => ({
  type: SET_PRODUCTID_SELECTED,
  productId,
  isSelected
});

export const toggleDetailsPopupWithId = (productId, lozengeName, linkedListParentId, context) => ({
  type: TOGGLE_DETAILS_POPUP_ID,
  productId,
  lozengeName,
  linkedListParentId,
  context
});

export const loadLicenceData = () => ({
  type: LOAD_LICENCE_DATA
});
export const storeLicenceData = licenceData => ({
  type: STORE_LICENCE_DATA,
  licenceData
});

export const licenceDataLoadedMultiple = ({
  licencesAvailable,
  teacherAssignLicencesMultiple,
  licencesTypes,
  userIdsInOrder,
  proposedAssignments,
  usersIdsWithLicences,
  usersExistingLicenceDetails,
  usersWithoutAssignments,
  userIdsWithoutPermission,
  teacherIdsInOrder,
  learnerUserIdsInOrder,
  licencesAssignDetails,
  isVisibleWhoIsGettingLicence
}) => ({
  type: LICENCE_DATA_LOADED_MULTIPLE,
  licencesAvailable,
  teacherAssignLicencesMultiple,
  licencesTypes,
  userIdsInOrder,
  proposedAssignments,
  usersIdsWithLicences,
  usersExistingLicenceDetails,
  usersWithoutAssignments,
  userIdsWithoutPermission,
  teacherIdsInOrder,
  learnerUserIdsInOrder,
  licencesAssignDetails,
  isVisibleWhoIsGettingLicence
});

export const licenceDataLoaded = (
  licencesAvailable,
  licencesTypes,
  userIdsInOrder,
  proposedAssignments,
  usersIdsWithLicences,
  usersExistingLicenceDetails,
  usersWithoutAssignments,
  userIdsWithoutPermission,
  teacherIdsInOrder,
  learnerUserIdsInOrder
) => ({
  type: LICENCE_DATA_LOADED,
  licencesAvailable,
  licencesTypes,
  userIdsInOrder,
  proposedAssignments,
  usersIdsWithLicences,
  usersExistingLicenceDetails,
  usersWithoutAssignments,
  userIdsWithoutPermission,
  teacherIdsInOrder,
  learnerUserIdsInOrder
});
export const licenceDataFailed = () => ({
  type: LICENCE_DATA_FAILED
});
export const setAssignLicences = (assignLicences, productId) => ({
  type: SET_ASSIGN_LICENCES,
  assignLicences,
  productId
});

export const setTeacherAssignLicences = teacherAssignLicences => ({
  type: SET_TEACHER_ASSIGN_LICENCES,
  teacherAssignLicences
});

// Navigation Actions
export const backToSearch = () => ({
  type: BACK_TO_SEARCH
});
export const gotoReviewAssignment = () => ({
  type: GOTO_REVIEW_ASSIGNMENT
});
export const backToReviewLicences = () => ({
  type: BACK_TO_REVIEW_LICENCES
});
export const gotoReviewLicences = () => ({
  type: GO_TO_REVIEW_LICENCES
});

// Submission Actions
export const submitAssignment = () => ({
  type: SUBMIT_ASSIGNMENT
});
export const showConfirmation = errorOccurred => ({
  type: SHOW_CONFIRMATION,
  errorOccurred
});

// Reset Action
export const resetJourney = () => ({
  type: RESET_JOURNEY
});
