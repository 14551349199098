import classnames from 'classnames';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { ORG_REGISTRATION_STATUS } from '@oup/shared-node-browser/constants';
import styles from './AppLayout.scss';
import { formStates } from '../../redux/reducers/registration/registration.reducer';
import { formStates as invitationFormStates } from '../../redux/reducers/invitationsBanner.reducer';
import cmsContent from '../../utils/cmsContent.js';
import ErrorPage from '../StatePages/ErrorPage/ErrorPage.js';
import IncompleteAccount from '../../routes/IncompleteAccount/IncompleteAccount.js';
import HeadersAndBanners from '../HeadersAndBanners/HeadersAndBanners.js';
import LoadingPage from '../StatePages/LoadingPage/LoadingPage.js';
import SessionExpiredPopup from '../SessionExpiredPopup/SessionExpiredPopup.js';
import SiteFooter from '../SiteFooter/SiteFooter.js';
import SitewideProductPopup from '../SitewideProductPopup/SitewideProductPopup.js';
import { closeEditMyAccountModal as closeEditMyAccountModalAction } from '../../redux/actions/editUserAccountModal';

import {
  isEmbeddedInIframe,
  isOrbMode,
  isOteMode,
  getCurrentPlatform,
  epsPlatformOlbOffline,
  epsPlatformEltHub,
  epsPlatformOrb,
  epsPlatformOte,
  epsPlatformOpt,
  epsPlatformOic,
  epsPlatformInsp,
  isOicMode,
  isCesMode,
  isContentPreviewMode,
  isEbookSampleMode,
  isMflMode
} from '../../utils/platform';
import { setFavicons } from '../../globals/favicons';
import { socialIdpIsEnabled } from '../../globals/envSettings';
import USER_ROLES from '../../globals/userRoles.js';
import MyProfileInitialEditPanel from '../../panels/MyProfileInitialEditPanel';
import { isPublicPath } from '../../globals/authSettings';

import { USER_REGISTRATION_STATUS } from '../../globals/appConstants';
import MyProfileEditPanel from '../../panels/MyProfileEditPanel.js';
import ReCAPTCHA from '../../components/ReCAPTCHA/ReCAPTCHA.js';

const isSocialEnabledAndPathNotPublic = () =>
  socialIdpIsEnabled(getCurrentPlatform()) && !isPublicPath(window.location.pathname);

// This structural component wraps the current react-router component
// in a page layout containing the SiteHeader
class AppLayout extends Component {
  isTermsAcceptedForPlatform = platform => {
    const {
      olbTermsAndConditionsAccepted,
      cesTermsAndConditionsAccepted,
      hubTermsAndConditionsAccepted,
      orbTermsAndConditionsAccepted,
      oteTermsAndConditionsAccepted,
      optTermsAndConditionsAccepted,
      oicTermsAndConditionsAccepted,
      inspTermsAndConditionsAccepted
    } = this.props;

    switch (platform) {
      case epsPlatformEltHub:
        return hubTermsAndConditionsAccepted;
      case epsPlatformOrb:
        return orbTermsAndConditionsAccepted;
      case epsPlatformOte:
        return oteTermsAndConditionsAccepted;
      case epsPlatformOpt:
        return optTermsAndConditionsAccepted;
      case epsPlatformOlbOffline:
        return olbTermsAndConditionsAccepted;
      case epsPlatformOic:
        return oicTermsAndConditionsAccepted;
      case epsPlatformInsp:
        return inspTermsAndConditionsAccepted;
      default:
        return cesTermsAndConditionsAccepted;
    }
  };

  showMissingFieldCheck = () => {
    const { ebookSampSelfSelectedRole, ebookSampClaimedSchool, ebookSampleCountryCode } = this.props;

    return (
      !isPublicPath(window.location.pathname) &&
      isEbookSampleMode() &&
      !(ebookSampSelfSelectedRole && ebookSampClaimedSchool && ebookSampleCountryCode) &&
      this.isTermsAcceptedForPlatform(getCurrentPlatform())
    );
  };

  showInitialConsentCheck = () => {
    const { isSocialAccount } = this.props;
    return (
      isSocialEnabledAndPathNotPublic() && isSocialAccount && !this.isTermsAcceptedForPlatform(getCurrentPlatform())
    );
  };

  render() {
    const {
      location,
      appError,
      appErrorReference,
      appReady,
      partialRegistrationInProgress,
      registrationComplete,
      favIcons,
      invites,
      children,
      invitesFetchStatus,
      history,
      currentOrganisation,
      currentOrganisationId,
      currentOrganisationRole,
      isSocialAccount,
      privacyPolicyAccepted,
      cesTermsAndConditionsAccepted,
      marketingPrefAccepted,
      embedded,
      editAccountModalOpen,
      closeEditMyAccountModal
    } = this.props;
    const CMS = cmsContent.appLayout || {};
    const isEmbedded = embedded || isEmbeddedInIframe() || sessionStorage.getItem('embedded-by-url') === 'true';
    const query = new URLSearchParams(location.search);
    const hasPendingInvites = invites.length;
    let hideRegisterLink = false;
    let hideSignInLink = false;
    let hideWidgetDropDown = false;
    let hideSubHeader = partialRegistrationInProgress || invitesFetchStatus || isOicMode() || isEbookSampleMode();
    let disableLogoLink = false;
    let hideFooter = false;
    let hideHelpLink = false;
    let hideMyAccountText = !!isCesMode();
    let hideHeader = false;
    const customClassForStudentAnswerView = location.pathname.startsWith('/studentAnswerView');
    const customClassForJoinClass = !privacyPolicyAccepted || !cesTermsAndConditionsAccepted;
    const customClassForOicPlatform = isOicMode();

    switch (true) {
      case location.pathname.startsWith('/reset-password'):
        hideRegisterLink = true;
        hideSignInLink = true;
        hideWidgetDropDown = true;
        break;
      // partial registration /register/:token
      case /^\/register\/[^/]+$/.test(location.pathname):
        hideSignInLink = true;
        hideRegisterLink = true;
        disableLogoLink = true;
        hideFooter = true;
        hideHelpLink = true;
        break;
      case location.pathname === '/register':
        hideRegisterLink = true;
        break;
      case location.pathname === '/register-choice':
        hideRegisterLink = true;
        hideSignInLink = true;
        hideMyAccountText = true;
        disableLogoLink = true;
        hideSubHeader = true;
        break;
      case location.pathname.startsWith('/offlineApp'):
        hideRegisterLink = true;
        hideSignInLink = true;
        hideMyAccountText = true;
        disableLogoLink = true;
        hideSubHeader = true;
        hideHelpLink = true;
        hideWidgetDropDown = true;
        break;
      // full org registration page
      case location.pathname === '/org/register':
        hideWidgetDropDown = false;
        hideSubHeader = true;
        break;
      // partial org registration page
      case /^\/org\/[^/]+\/complete-registration$/.test(location.pathname):
        hideWidgetDropDown = true;
        hideSubHeader = true;
        disableLogoLink = true;
        hideFooter = true;
        hideHelpLink = true;
        break;
      case /support\/mfl/.test(location.pathname) && isMflMode():
        hideRegisterLink = true;
        hideSignInLink = true;
        hideMyAccountText = true;
        hideSubHeader = true;
        hideHelpLink = true;
        break;
      case /download\/mfl/.test(location.pathname) && isMflMode():
        hideRegisterLink = true;
        hideSignInLink = true;
        hideMyAccountText = true;
        break;
      case location.pathname === '/support/contact-us' && Boolean(query.get('platform')):
        hideSignInLink = true;
        hideRegisterLink = true;
        hideFooter = true;
        break;
      case location.pathname.startsWith('/support') && isOrbMode():
        hideSignInLink = true;
        hideRegisterLink = true;
        break;
      case location.pathname.startsWith('/support') && isCesMode():
        hideHelpLink = true;
        break;

      case location.pathname === '/redeem':
        hideRegisterLink = true;
        hideSignInLink = true;
        hideFooter = true;
        hideMyAccountText = true;
        disableLogoLink = true;
        hideSubHeader = true;
        break;
      case location.pathname.startsWith('/studentAnswerView'):
        hideRegisterLink = true;
        hideSignInLink = true;
        hideFooter = true;
        hideHelpLink = false;
        hideMyAccountText = false;
        disableLogoLink = true;
        hideSubHeader = true;
        break;
      case /^\/org\/[^/]*\/batchId\/[^/]*\/records\/[^/]*$/.test(location.pathname):
        hideRegisterLink = true;
        hideSignInLink = true;
        hideFooter = true;
        hideHelpLink = true;
        hideMyAccountText = true;
        disableLogoLink = true;
        hideSubHeader = true;
        hideWidgetDropDown = true;
        break;
      case location.pathname === '/redeem/code':
        hideRegisterLink = true;
        hideSignInLink = true;
        hideFooter = true;
        hideHelpLink = true;
        hideMyAccountText = true;
        disableLogoLink = true;
        hideSubHeader = true;
        break;
      case location.pathname.startsWith('/decline-invite'):
        hideRegisterLink = true;
        hideSignInLink = true;
        hideFooter = true;
        hideHelpLink = true;
        hideMyAccountText = true;
        disableLogoLink = true;
        hideSubHeader = true;
        break;
      case location.pathname === '/change-username':
        hideRegisterLink = true;
        break;
      case location.pathname === '/logged-out':
        hideWidgetDropDown = true;
        break;
      default:
    }

    let showRecaptchaFooter = false;
    if (location.pathname === '/') showRecaptchaFooter = true;

    // Hide both sign in and register links on registration complete state for both partial (/register/:token) and full reg (/register)
    if (location.pathname.startsWith('/register') && registrationComplete) {
      hideSignInLink = true;
      hideRegisterLink = true;
    }
    if (location.pathname.startsWith('/join-class') || location.pathname.startsWith('/edit-account')) {
      hideHeader = true;
    }

    if (isOteMode()) {
      hideRegisterLink = true;
    }

    if (appError) {
      return <ErrorPage appErrorReference={appErrorReference} showHeader={!isEmbedded} />;
    }

    if (!appReady) {
      return <LoadingPage showHeader={!isEmbedded} customLogoComponent={isOrbMode() ? <div /> : null} />;
    }

    if (isEmbedded || isOrbMode()) {
      hideFooter = true;
    }

    if (location.pathname.includes('orb-start-tips') && isOrbMode()) {
      hideSubHeader = true;
      hideFooter = true;
    }

    if (isOicMode()) {
      hideRegisterLink = true;
      const isHomepage = /^\/?$/.test(window.location.pathname);
      hideFooter = !isHomepage;
    }

    // Redirecting ADMIN user to complete org registration route.
    if (
      !/^\/org\/[^/]+\/complete-registration$/.test(location.pathname) &&
      currentOrganisation &&
      currentOrganisation.registrationStatus === ORG_REGISTRATION_STATUS.PARTIALLY_REGISTERED &&
      currentOrganisationRole === USER_ROLES.ORG_ADMIN
    ) {
      history.push(`/org/${currentOrganisationId}/complete-registration`);
    }

    return (
      <ReCAPTCHA>
        <div id="app" className={classnames({ [styles.app]: !isOicMode(), [styles.appFullHeight]: isOicMode() })}>
          <Helmet
            htmlAttributes={{ lang: 'en', 'data-platform': getCurrentPlatform(), 'data-page': location.pathname }}
            title={CMS.pageTitle || 'Loading'}
            titleTemplate={CMS.titleTemplate || '%s | Oxford University Press'}
            link={favIcons}
          />
          <div>
            {hideHeader ? null : (
              <HeadersAndBanners
                isEmbedded={isEmbedded}
                hideSubHeader={hideSubHeader}
                hideRegisterLink={hideRegisterLink}
                hideSignInLink={hideSignInLink}
                hideWidgetDropDown={hideWidgetDropDown}
                hideHelpLink={hideHelpLink}
                hideMyAccountText={hideMyAccountText}
                disableLogoLink={disableLogoLink}
                hasPendingInvites={hasPendingInvites}
                hideBurgerMenu={isOicMode()}
              />
            )}
            <div className={classnames(styles.pageContainer)}>
              <main
                id="maincontent"
                className={classnames(
                  styles.transparentBackground,
                  {
                    [styles.noMinHeight]: customClassForStudentAnswerView,
                    [styles.whiteBackground]: customClassForJoinClass,
                    [styles.noMinHeight]: customClassForOicPlatform,
                    [styles.fullFluidHeight]: customClassForOicPlatform
                  },
                  isContentPreviewMode() ? styles.noMinHeight : ''
                )}
              >
                {/* do not show MyProfileInitialEditPanel on public paths */}
                {this.showInitialConsentCheck() || this.showMissingFieldCheck() ? (
                  <MyProfileInitialEditPanel
                    ebookSampleMissingFields={this.showMissingFieldCheck()}
                    profileInitialConsent={this.showInitialConsentCheck()}
                    isOpen
                    platform={getCurrentPlatform()}
                  />
                ) : null}
                {/* If the user is partially registered and not trying to access support pages then force them to finish */}
                {!isSocialAccount &&
                !isPublicPath(window.location.pathname) &&
                (!marketingPrefAccepted || !this.isTermsAcceptedForPlatform(getCurrentPlatform())) ? (
                  <IncompleteAccount
                    platform={getCurrentPlatform()}
                    isTandCaccepted={this.isTermsAcceptedForPlatform(getCurrentPlatform())}
                  />
                ) : (
                  children
                )}
                <SitewideProductPopup content={CMS} />
                <SessionExpiredPopup />
              </main>
              {hideFooter ? null : <SiteFooter showRecaptchaFooter={showRecaptchaFooter} />}
              {isOicMode() ? (
                <MyProfileEditPanel isOpen={editAccountModalOpen} closePanel={closeEditMyAccountModal} />
              ) : null}
            </div>
          </div>
        </div>
      </ReCAPTCHA>
    );
  }
}

const platform = isOrbMode() ? 'orb' : 'default';

export default withRouter(
  connect(
    state => {
      let currentOrganisation = null;
      if (state.identity.currentOrganisationId) {
        currentOrganisation = state.organisations.data[state.identity.currentOrganisationId];
      }

      return {
        appError: state.app.appError,
        appErrorReference: state.app.appErrorReference,
        appReady: state.app.appReady,
        isSocialAccount: state.identity.isSocial,
        partialRegistrationInProgress:
          state.identity.registrationStatus !== USER_REGISTRATION_STATUS.REGISTRATION_COMPLETE,
        olbTermsAndConditionsAccepted: !state.identity.missingFields.includes('olbTsAndCsAccepted'),
        cesTermsAndConditionsAccepted: !state.identity.missingFields.includes('tsAndCsAccepted'),
        hubTermsAndConditionsAccepted: !state.identity.missingFields.includes('hubTsAndCsAccepted'),
        orbTermsAndConditionsAccepted: !state.identity.missingFields.includes('orbTsAndCsAccepted'),
        oteTermsAndConditionsAccepted: !state.identity.missingFields.includes('oteTsAndCsAccepted'),
        optTermsAndConditionsAccepted: !state.identity.missingFields.includes('optTsAndCsAccepted'),
        oicTermsAndConditionsAccepted: !state.identity.missingFields.includes('oicTsAndCsAccepted'),
        inspTermsAndConditionsAccepted: !state.identity.missingFields.includes('inspTsAndCsAccepted'),
        privacyPolicyAccepted: !state.identity.missingFields.includes('privacyPolicyAccepted'),
        marketingPrefAccepted: !state.identity.missingFields.includes('marketingPref'),
        registrationComplete: state.registration.formState === formStates.COMPLETE,
        invites: state.invites.invites,
        favIcons: setFavicons(platform),
        invitesFetchStatus: state.invites.formState !== invitationFormStates.INPUTTING && !!state.identity.role,
        currentOrganisation,
        tcLocalizedContentLoaded: state.tcLocalizedContentLoaded,
        currentOrganisationId: state.identity.currentOrganisationId,
        currentOrganisationRole: state.identity.role,
        ebookSampSelfSelectedRole: state.identity.selfSelectedRole,
        ebookSampClaimedSchool: state.identity.claimedSchool,
        ebookSampleCountryCode: state.identity.countryCode,
        editAccountModalOpen: state.editUserAccountModal.editAccountModalOpen
      };
    },
    {
      closeEditMyAccountModal: closeEditMyAccountModalAction
    }
  )(AppLayout)
);

AppLayout.propTypes = {
  children: PropTypes.any,
  location: PropTypes.object.isRequired,
  appError: PropTypes.bool.isRequired,
  appErrorReference: PropTypes.string,
  appReady: PropTypes.bool.isRequired,
  partialRegistrationInProgress: PropTypes.bool.isRequired,
  registrationComplete: PropTypes.bool.isRequired,
  favIcons: PropTypes.array,
  invites: PropTypes.array,
  embedded: PropTypes.bool,
  invitesFetchStatus: PropTypes.bool,
  currentOrganisation: PropTypes.object,
  currentOrganisationId: PropTypes.string,
  currentOrganisationRole: PropTypes.string,
  olbTermsAndConditionsAccepted: PropTypes.bool,
  cesTermsAndConditionsAccepted: PropTypes.bool,
  hubTermsAndConditionsAccepted: PropTypes.bool,
  orbTermsAndConditionsAccepted: PropTypes.bool,
  oteTermsAndConditionsAccepted: PropTypes.bool,
  optTermsAndConditionsAccepted: PropTypes.bool,
  oicTermsAndConditionsAccepted: PropTypes.bool,
  inspTermsAndConditionsAccepted: PropTypes.bool,
  privacyPolicyAccepted: PropTypes.bool,
  marketingPrefAccepted: PropTypes.bool,
  isSocialAccount: PropTypes.bool,
  history: PropTypes.object.isRequired,
  editAccountModalOpen: PropTypes.bool.isRequired,
  closeEditMyAccountModal: PropTypes.func.isRequired,
  ebookSampSelfSelectedRole: PropTypes.string,
  ebookSampClaimedSchool: PropTypes.string,
  ebookSampleCountryCode: PropTypes.string
};
