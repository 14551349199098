import { put } from 'redux-saga/effects';
import getLicensesFetch from '../../../apiCalls/offlineLicenseStatus/offlineLicenseStatus';
import { getLicenseStatusSuccess, getLicenseStatusFailure } from '../../../../actions/offlineContentPlayer';

function* getOfflineLicenseStatus(params) {
  const response = yield getLicensesFetch(params);
  if (response.status === 'success') {
    yield put(getLicenseStatusSuccess(response.data));
  } else {
    yield put(getLicenseStatusFailure('Failed to fetch offline licenses'));
  }
}

export default getOfflineLicenseStatus;
