import * as t from '../../actionTypes';

const getLocalStoredState = () => {
  const storedProducts = localStorage.getItem('offline_products');
  const products = storedProducts ? JSON.parse(storedProducts) : {};

  const storedProfiles = localStorage.getItem('offline_profiles');
  const profiles = storedProfiles ? JSON.parse(storedProfiles) : {};

  const storedUnits = localStorage.getItem('offline_units');
  const units = storedUnits ? JSON.parse(storedUnits) : [];

  const storedPendingActionsTime = localStorage.getItem('offline_pending_actions_time');
  const pendingActionsTime = storedPendingActionsTime ? JSON.parse(storedPendingActionsTime) : {};

  const storedOfflineLicenseStatusTime = localStorage.getItem('offline_license_status_time');
  const offlineLicenseStatusTime = storedOfflineLicenseStatusTime ? JSON.parse(storedOfflineLicenseStatusTime) : {};

  const storedVersion = localStorage.getItem('offline_player_version');
  const version = storedVersion || '';

  return {
    products,
    profiles,
    units,
    pendingActionsTime,
    offlineLicenseStatusTime,
    version,
    offlineDownloadProgress: {}
  };
};

export const initialState = {
  products: {},
  profiles: {},
  units: [],
  pendingActionsTime: {},
  isLoading: false,
  contentStatus: {},
  error: null,
  version: '',
  licenses: null,
  offlineLicenseStatusTimer: {}
};

export default function offlineContentPlayer(state = { ...initialState, ...getLocalStoredState() }, action = {}) {
  switch (action.type) {
    case t.LOAD_OFFLINE_PRODUCTS: {
      return {
        ...state,
        products: action.payload
      };
    }
    case t.LOAD_OFFLINE_PROFILES: {
      return {
        ...state,
        profiles: action.payload
      };
    }
    case t.LOAD_OFFLINE_UNITS: {
      return {
        ...state,
        units: action.payload
      };
    }
    case t.GET_OFFLINE_PENDING_ACTIONS_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case t.GET_OFFLINE_PENDING_ACTIONS_SUCCESS: {
      const actionsByID = {};
      action.payload?.forEach(act => {
        const { actionId, actionType } = act;

        if (!actionsByID[actionId]) {
          actionsByID[actionId] = [];
        }

        actionsByID[actionId].push(actionType);
      });

      return {
        ...state,
        contentStatus: {
          ...state.contentStatus,
          ...actionsByID
        },
        success: true,
        isLoading: false
      };
    }
    case t.GET_OFFLINE_PENDING_ACTIONS_FAILURE:
      return {
        ...state,
        contentStatus: null,
        error: action.payload,
        isLoading: false
      };
    case t.LOAD_PENDING_ACTIONS_TIME:
      return {
        ...state,
        pendingActionsTime: action.payload
      };
    case t.LOAD_OFFLINE_PLAYER_VERSION: {
      return {
        ...state,
        version: action.payload
      };
    }
    case t.GET_OFFLINE_LICENSE_REQUEST: {
      return {
        ...state,
        licenses: action.payload,
        isLoading: true
      };
    }
    case t.GET_OFFLINE_LICENSE_SUCCESS: {
      return {
        ...state,
        licenses: action.payload,
        success: true,
        isLoading: false
      };
    }
    case t.GET_OFFLINE_LICENSE_FAILURE: {
      return {
        ...state,
        licenses: null,
        error: action.payload,
        isLoading: false
      };
    }

    case t.GET_OFFLINE_LICENSE_STATUS_TIMER: {
      return {
        ...state,
        offlineLicenseStatusTimer: action.payload
      };
    }

    case t.LOAD_OFFLINE_LICENSE_STATUS_TIMER:
      return {
        ...state,
        offlineLicenseStatusTimer: action.payload
      };

    case t.SET_OFFLINE_DOWNLOAD_PROGRESS: {
      const { unitId, activityId, downloadStatus } = action.payload;

      const tempDownloadProgress = { ...state.offlineDownloadProgress };

      if (!tempDownloadProgress[unitId]) {
        tempDownloadProgress[unitId] = {};
      }

      tempDownloadProgress[unitId][activityId] = downloadStatus;

      return {
        ...state,
        offlineDownloadProgress: tempDownloadProgress
      };
    }
    default:
      return { ...state };
  }
}
