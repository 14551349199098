/* eslint-disable import/prefer-default-export */
import { roleIsAtLeast, userRoles } from '@oup/shared-node-browser/user';
import APP_CONSTANTS from '../../sharedNodeBrowser/constants';
import { featureIsEnabled } from './envSettings';

export const HubLayoutConstants = {
  THEME_NAME: 'hub-theme',
  TOP_LEVEL_PLATFORM: 'eps-platform-code',
  PLATFORM_NAME: 'hub',
  DASHBOARD_DEFAULT_VIEW: 'myClasses',
  DASHBOARD_COURSES_VIEW: 'myCourses',
  DASHBOARD_SESSIONS_VIEW: 'sessions',
  DASHBOARD_TASKS_DEFAULT_VIEW: 'toDo',
  HUB_ROW_HEADER_TYPES: {
    PEOPLE: 'people',
    ORG_CLASSES: 'org_classes'
  },
  HELP_AND_SUPPORT_URL: 'https://support.englishhub.oup.com',
  HOW_TO_ADD_AN_LTI_URL: 'https://support.englishhub.oup.com/lti-organizations/',
  ONLINE_TEST_STEPS: {
    CHOOSE_CLASS_PAGE: 'class',
    CHOOSE_TEST_PAGE: 'assessment-tests',
    CHOOSE_STUDENTS_PAGE: 'students',
    CHOOSE_TIME_PAGE: 'time',
    COMMUNICATION_PREFERENCE_PAGE: 'communication',
    REVIEW_SETTINGS_PAGE: 'settings',
    EDIT_TEST_SETTINGS_PAGE: 'edit-settings',
    CANCEL_TEST_CONFIRMATION: 'cancel-test-confirmation',
    CANCEL_TEST_FAILURE: 'cancel-test-failure',
    CANCEL_TEST_SUCCESS: 'cancel-test-success',
    CONFIRMATION_PAGE: 'confirmation-page',
    EDIT_TEST_FAILURE: 'edit-test-failure'
  },
  PATH_NAMES: {
    HOME_PAGE: '/',
    WELCOME_HOME_PAGE: '/welcome-to-hub',
    REGISTER_PATH: '/register',
    REGISTER_CHOICE_PATH: '/register-choice',
    DECLINE_ORG_INVITE: '/decline-invite',
    RESET_PASSWORD: '/reset-password',
    REGISTER_ORGANIZATION: '/org/register',
    REDEEM_PATH: '/redeem',
    DASHBOARD_PATH: '/myDashboard',
    ORGANIZATION_PATH: '/myOrganization',
    TASKS_PATH: '/myTasks',
    DASHBOARD_REDEEM_PATH: '/myDashboard/redeem',
    COURSES_PATH: '/myCourses',
    CLASSES_PATH: '/myClasses',
    SUPPORT_PATH: '/support',
    SINGLE_COURSE_PATH: '/singleCourse',
    SINGLE_CLASS_PATH: '/singleClass',
    PROGRESS: '/progress',
    GRADEBOOK: '/gradebook',
    STUDENT_ANSWER: '/studentAnswerView',
    CLASSWORK: '/classwork',
    PEOPLE: '/people',
    STUDENTS: '/students',
    STAFF: '/staff',
    CLASSES: '/classes',
    PLACEMENT_TESTS: '/placementTests',
    MY_PROGRESS: '/myProgress',
    LICENCES: '/licences',
    CREDITS: '/credits',
    PROFILE_PAGE: '/myProfile',
    SCHOOL_PAGE: '/mySchool',
    DASHBOARD_ADMIN: '/dashboard',
    PRODUCTS_ADMIN: '/products',
    CLASS_ASSESSMENT_PATH: '/assessment',
    ASSESSMENT_HOME: '/assessmentHome',
    RESOURCE_HOME: '/resourceHome',
    GET_STARTED: '/getStarted',
    GET_STARTED_HOME: '/getStartedHome',
    PROFESSIONAL_DEVELOPMENT: '/professionalDevelopment',
    PROFESSIONAL_DEVELOPMENT_HOME: '/professionalDevelopmentHome',
    COURSE_MATERIAL: '/courseMaterial',
    ASSESSMENT_FOLDER: '/assessmentFolder',
    ASSESSMENTS: '/assessments',
    ASSESSMENT: '/assessment',
    RESOURCE_FOLDER: '/resourceFolder',
    RESOURCES: '/resources',
    TEST: '/test',
    TESTS: '/tests',
    TEST_HOME: '/testHome',
    PRODUCT_PAGE: '/product',
    LMS_CONFIG: '/lmsConfig'
  },
  LOCKED_KEYWORD: 'comingSoon',
  ASSESSMENT_TYPES: {
    RESOURCE: 'resource',
    INTERACTIVE_FOLDER: 'interactiveFolder'
  },
  TEST_BUILDER_CONSTANTS: {
    HUB_RETURN_PATH: `/static/pages/testBuilder/index.html`
  },
  COURSE_FOLDERS: {
    ASSESSMENT_FOLDER: 'assessmentFolder',
    RESOURCE_FOLDER: 'resourceFolder'
  },
  PRODUCT_SELECTION_LIMIT: 10,
  PRODUCTS_NAME: 'products',
  TILE_LIMIT_MOBILE: 4,
  SUBMENU_ITEMS_LIMIT: 4,
  NAVBAR_TRANSITION_TIME: 300,
  MAX_PEOPLE_PER_PAGE: 10,
  MAX_ORGANIZATION_CLASSES_PER_PAGE: 10,
  MAX_ORGANIZATION_CLASSES_SELECTION_FOR_ARCHIVE: 5,
  MAX_LMS_TOOLS_PER_PAGE: 10,
  FILTER_PANEL_TYPES: {
    MATERIALS: 'MATERIALS',
    ADD_STUDENTS_CLASS: 'ADD_STUDENTS_CLASS'
  },
  ASSESSMENT_HOME: 'AssessmentHome',
  DOWNLOAD_TYPES: {
    VST: 'vst',
    LOR: 'lor'
  },
  SELF_SELECT_ROLES: [
    {
      title: 'Student',
      key: 'SELF_LEARNER',
      subTitle: '',
      illustrationSrc: '/media/feedback/illustration-class-students.svg',
      illustrationAlt: 'Student illustration'
    },
    {
      title: 'Teacher or Administrator',
      key: 'SELF_TEACHER',
      subTitle: '',
      illustrationSrc: '/media/feedback/illustration-teacher-setting-hwork.svg',
      illustrationAlt: 'Teacher setting homework illustration'
    }
  ],
  OUP_ROLES: [userRoles.OUP_ADMIN, userRoles.OUP_SUPPORT],
  COURSE_USER_ROLES: {
    TEACHER: 'teacher',
    STUDENT: 'student'
  },
  PEOPLE_STATUS: {
    ARCHIVED: 'ARCHIVED',
    PENDING: 'PENDING',
    ACTIVE: 'ACTIVE'
  },
  PEOPLE_PANEL_TYPES: {
    ADD_STUDENTS: 'addStudents',
    ADD_STAFF: 'addStaff',
    REMOVE_STUDENTS: 'removeStudents',
    REMOVE_TEACHERS: 'removeTeachers',
    REMOVE_MULTIPLE_USERS: 'removeMultipleUsers',
    ASSIGN_MATERIALS_TO_USERS: 'assignMaterialsToClassUsers',
    ASSIGN_MATERIAL: 'assignMaterial',
    ARCHIVE_USERS: 'archiveUsers',
    RESEND_INVITATION: 'resendInvitation',
    RESET_PASSWORD_REQUEST: 'sendPasswordResetEmail',
    REGENERATE_SUPERVISED_USER_SIGN_IN_CARD: 'regenerateSupervisedUserSignInCard',
    BULK_PASSWORD_RESET: 'bulkPasswordReset'
  },
  CLASSWORK_PANEL_TYPES: {
    REMOVE_MATERIALS_FROM_CLASS: 'removeMaterialsFromClass',
    LOCK_PRODUCT_CONTENT: 'lockProductContent'
  },
  ORG_LICENCES_STATUS: {
    CHECK_COUNT: 10,
    WARNING_COUNT: 1,
    WARNING_RED_COUNT: 0,
    CHECK_COLOUR: '#2B8250',
    WARNING_COLOUR: '#B85C00',
    WARNING_RED_COLOUR: '#C92467',
    BLUE_COLOR: '#002147'
  },
  LICENCES_CONTEXT: {
    CLASS: 'CLASS',
    CLASS_ONBOARDING_WIZARD: 'CLASS_ONBOARDING_WIZARD',
    CLASSWORK: 'CLASSWORK',
    CLASSWORK_MANAGE: 'CLASSWORK_MANAGE',
    ORG: 'ORG',
    MY_COURSES: 'MY_COURSES'
  },
  TARGET_USERTYPE: {
    STUDENT: 'student',
    TEACHER: 'teacher'
  },
  ASSESSMENT_USER_ROLES: {
    INSTRUCTOR: 'Instructor',
    STUDENT: 'Student'
  }
};

export const ResourceConstants = {
  PREVIEWABLE_FORMATS: ['mp3', 'mp4', 'epub', 'json', 'asmt']
};

export const submitActions = {
  CANCEL: 'CANCEL',
  SET: 'SET',
  UPDATE: 'UPDATE'
};

export const RESOURCE_TYPE = {
  COURSE_RESOURCE: 'COURSE_RESOURCE',
  ASSESSMENT: 'ASSESSMENT'
};

export const RESOURCE_DOWNLOAD_TYPE = {
  YES: 'yes',
  NO: 'no'
};

export const LTI_ACTIONS = {
  DOWNLOAD: 'download',
  PLAY: 'play',
  OPEN: 'open'
};

export const COURSE_FOLDERS = {
  GET_STARTED: 'getStarted',
  PROFESSIONAL_DEVELOPMENT: 'professionalDevelopment',
  TESTS: 'tests',
  RESOURCES: 'resources'
};

export const DATE_CONSTANTS = {
  NO_DATE: 'NO DATE'
};

export const ErrorModalType = {
  NONE: 0,
  EXPIRED: 1,
  NOT_STARTED: 2
};

export const PLATFORMS = {
  OLB: 'OLB',
  VST: 'VST',
  CBA: 'CBA',
  ELTCORE: 'ELTCORE'
};

export const TEST_STATUS = {
  ACTIVE: 'active',
  UPCOMING: 'upcoming',
  COMPLETED: 'completed'
};

export const INSTANCE_ROLES = {
  ORG_STAFF: 'orgStaff',
  ORG_STUDENTS: 'orgStudents'
};

export const ONLINE_TEST_PANEL_CONTEXT = {
  CLASS_CONTEXT: 'CLASS_CONTEXT',
  COURSE_CONTEXT: 'COURSE_CONTEXT'
};

export const SINGLE_CLASS_PAGES_CONTEXT = {
  CLASS_CONTEXT: 'CLASS_CONTEXT',
  ORG_CONTEXT: 'ORG_CONTEXT'
};

export const productInformationContext = {
  MY_COURSES: 'MY_COURSES',
  CLASS_MATERIALS: 'CLASS_MATERIALS',
  USER_PROFILE_MATERIALS: 'USER_PROFILE_MATERIALS',
  ORGANIZATION_LICENCES: 'ORGANIZATION_LICENCES',
  PRODUCT_FINDER: 'PRODUCT_FINDER'
};

export const orderStrings = {
  1: 'first',
  2: 'second',
  3: 'third',
  4: 'fourth',
  5: 'fifth',
  6: 'sixth',
  7: 'seventh',
  8: 'eighth',
  9: 'ninth'
};

export const getDashboardTabs = (hubContent, classesCount, coursesCount, areClassesLoading, areCoursesLoading) => [
  {
    name: hubContent.my_classes,
    count: classesCount,
    key: 'myClasses',
    isLoading: areClassesLoading
  },
  {
    name: hubContent.my_courses,
    count: coursesCount,
    key: 'myCourses',
    isLoading: areCoursesLoading
  }
];

export const getOrgPlacementTestCenterTabs = (
  hubContent,
  sessionsCount,
  coursesCount,
  areSessionsLoading,
  areCoursesLoading
) => [
  {
    name: hubContent.sessions,
    count: sessionsCount,
    key: 'sessions',
    isLoading: areSessionsLoading
  },
  {
    name: hubContent.my_courses,
    count: coursesCount,
    key: 'myCourses',
    isLoading: areCoursesLoading
  }
];

export const getPeopleHeaderItems = (hubContent, openAddTeacherModalAction, toggleAddStudentsModalAction) => [
  {
    name: hubContent.people_header_teacher_text,
    status: hubContent.people_header_status_text,
    buttonText: hubContent.add_teachers,
    buttonAction: openAddTeacherModalAction,
    role: userRoles.TEACHER
  },
  {
    name: hubContent.people_header_student_text,
    status: hubContent.people_header_status_text,
    buttonText: hubContent.add_students,
    buttonAction: toggleAddStudentsModalAction,
    role: userRoles.LEARNER
  }
];

export const getOrganizationClassesHeaderItems = (hubContent, addClassAction = null) => [
  {
    name: hubContent.my_classes_page_title,
    classCode: hubContent.class_code,
    status: hubContent.people_header_status_text,
    buttonText: hubContent.add,
    buttonAction: addClassAction,
    isTeacherInClass: true
  },
  {
    name: hubContent.organization_classes_text,
    classCode: hubContent.class_code,
    status: hubContent.people_header_status_text,
    buttonText: hubContent.add,
    buttonAction: addClassAction,
    isTeacherInClass: false
  }
];

export const getOrgStaffHeaderItems = (content, hubContent, { addStaff = null }, currentOrganisationLti = false) => {
  const {
    PEOPLE_PANEL_TYPES: { ADD_STAFF }
  } = HubLayoutConstants;
  return [
    {
      name: content.table_school_admin_column,
      status: content.heading_status,
      buttonText: hubContent.add,
      action: (e, data = {}) =>
        addStaff(e, ADD_STAFF, { ...data, context: APP_CONSTANTS.ORG_STAFF, role: userRoles.ORG_ADMIN })
    },
    {
      name: currentOrganisationLti ? content.table_teachers_column : content.table_teacher_admin_column,
      status: content.heading_status,
      buttonText: hubContent.add,
      action: (e, data = {}) =>
        addStaff(e, ADD_STAFF, { ...data, context: APP_CONSTANTS.ORG_STAFF, role: userRoles.TEACHER_ADMIN })
    },
    {
      name: content.table_teachers_column,
      status: content.heading_status,
      buttonText: hubContent.add,
      action: (e, data = {}) =>
        addStaff(e, ADD_STAFF, { ...data, context: APP_CONSTANTS.ORG_STAFF, role: userRoles.TEACHER })
    }
  ];
};
export const getOrgStudentsHeaderItems = (content, hubContent, { addStudents = null }) => {
  const {
    PEOPLE_PANEL_TYPES: { ADD_STUDENTS }
  } = HubLayoutConstants;
  return [
    {
      name: content ? content.students_text : '',
      status: content ? content.heading_status : '',
      buttonText: hubContent.add,
      action: (e, data = {}) =>
        addStudents(e, ADD_STUDENTS, { ...data, context: APP_CONSTANTS.ORG_STUDENTS, role: userRoles.LEARNER })
    }
  ];
};

export const licenceStatus = {
  NO_LICENCE: 'noLicence',
  EXPIRED: 'expired',
  EXPIRING: 'expiring',
  ACTIVE: 'active',
  NOT_STARTED: 'notStarted'
};

export const getOrgStaffFooterActionButtons = (
  hubContent,
  userRole,
  { removeUsers = null, assignMaterial = null },
  canOrgadminRemoveTeachers,
  currentOrganisationLti = false
) => {
  const {
    PEOPLE_PANEL_TYPES: { ASSIGN_MATERIAL, ARCHIVE_USERS }
  } = HubLayoutConstants;

  const returnOptions = [];

  if (!currentOrganisationLti) {
    returnOptions.push({
      title: hubContent.assign_learning_material,
      action: (e, data) => assignMaterial(e, ASSIGN_MATERIAL, data)
    });
  }

  if (userRole === userRoles.ORG_ADMIN && canOrgadminRemoveTeachers) {
    returnOptions.push({
      title: hubContent.remove_from_organization,
      action: (e, data) => removeUsers(e, ARCHIVE_USERS, data)
    });
  }

  return returnOptions;
};

export const getOrgStudentsFooterActionButtons = (
  hubContent,
  userRole,
  { removeUsers = null, assignMaterial = null, bulkPasswordReset = null },
  currentOrganisationLti = false
) => {
  const {
    PEOPLE_PANEL_TYPES: { ASSIGN_MATERIAL, ARCHIVE_USERS }
  } = HubLayoutConstants;

  const returnOptions = [];
  if (!currentOrganisationLti) {
    returnOptions.push({
      title: hubContent.assign_learning_material,
      action: (e, data) => assignMaterial(e, ASSIGN_MATERIAL, data)
    });
  }

  if (userRole === userRoles.ORG_ADMIN) {
    returnOptions.push({
      title: hubContent.remove_from_organization,
      action: (e, data) => removeUsers(e, ARCHIVE_USERS, { ...data, context: APP_CONSTANTS.ORG_STUDENTS })
    });
  }
  if (roleIsAtLeast(userRoles.TEACHER_ADMIN, userRole) && featureIsEnabled('bulk-password-reset')) {
    returnOptions.push({
      title: hubContent.reset_password,
      action: (e, data) =>
        bulkPasswordReset(e, HubLayoutConstants.PEOPLE_PANEL_TYPES.BULK_PASSWORD_RESET, {
          ...data,
          context: APP_CONSTANTS.ORG_STUDENTS
        })
    });
  }
  return returnOptions;
};
export const licenceExpiryDates = {
  NEVER: 'Never'
};

export const MAX_PEOPLE = 100;

export const DEFAULT_OPTIONS_FILTER_STAFF_AND_STUDENTS_ORGANIZATION = {
  active: true,
  invited: true,
  archived: false
};

export const HubIllustrationConstants = {
  INFORMATION: '/media/feedback/illustration-information-sitting-books.svg',
  INVITED: '/media/feedback/illustration-invited-waiting.svg',
  WARNING: '/media/feedback/illustration-warning.svg',
  SUCCESS: '/media/feedback/illustration-success.svg',
  VALID: '/media/feedback/illustration-success.svg',
  ERROR: '/media/feedback/illustration-error-frustrated.svg',
  CLASS_STUDENTS: '/media/feedback/illustration-class-students.svg',
  DIGITAL_LIBRARY: '/media/feedback/illustration-digital-library.svg',
  EMAILS_SENT: '/media/feedback/illustration-emails-sent.svg',
  WELCOME: '/media/feedback/illustration_welcome_email.svg',
  ENTER_CODE: '/media/feedback/illustration-enter-code.svg',
  TEACHER_SETTING_HWORK: '/media/feedback/illustration-teacher-setting-hwork.svg',
  PROGRESS: '/media/feedback/illustration-progress.svg',
  TEST: '/media/feedback/illustration-test.svg',
  SEARCH: '/media/feedback/illustration-searching.svg',
  CHANGE_SETTINGS: '/media/feedback/illustration-change-settings.svg',
  LEARNING: '/media/feedback/illustration-learning.svg',
  STUDENT: '/media/feedback/illustration-student.svg',
  DIGITAL_LEARNING: '/media/feedback/illustration-digital-learning.svg',
  ORGANIZING: '/media/feedback/illustration-class-organizing.svg',
  DASHBOARD: '/media/hub/banners/my_dashboard_banner.png',
  SETUP: '/media/feedback/illustration-test.svg',
  WORD_OF_DAY_HEADER: '/media/feedback/illustration-warning-close-eyes.svg',
  ORANGE_MONSTER: '/media/young-learners/illustration-orange-monster.svg'
};

export const HubIllustrationAltText = {
  INFORMATION: 'Student sitting on books illustration',
  SUCCESS: 'High five illustration',
  VALID: 'High five illustration',
  WARNING: 'Worried student illustration',
  ERROR: 'Frustrated student illustration',
  CLASS_STUDENTS: 'Students with smiling faces',
  CHANGE_SETTINGS: 'Person pushing a toggle',
  SEARCH: 'Boy looking through binoculars',
  PROGRESS: 'A laptop and an hourglass',
  INVITED: 'Girl reading a book',
  STUDENT: 'Student sitting at table with laptop',
  DIGITAL_LEARNING: 'A student learning',
  ORGANIZING: 'A class photo with six students',
  DASHBOARD: 'A yellow circle',
  SETUP: 'Laptop with calendar illustration',
  WORD_OF_DAY_HEADER: 'Word of the day',
  ORANGE_MONSTER: 'Orange monster illustration'
};

export const REFRESH_CLASS_ASSESSMENTS_INTERVAL = 5 * 60 * 1000;
export const REFRESH_PLACEMENT_TESTS_INTERVAL = 5 * 60 * 1000;

export const USER_TYPES = {
  STUDENT: 'student',
  PARENT: 'parent',
  TEACHER: 'teacher'
};

export const USER_AGE = {
  UNDER_12: 'under_12',
  OVER_12: 'over_12'
};
