import PropTypes from 'prop-types';
import React from 'react';
import PopoutConfirmationList from '../PopoutConfirmationList/PopoutConfirmationList.js';
import ContentWithSVGIcon from '../ContentWithSVGIcon/ContentWithSVGIcon';
import PersonRepresentation from '../PersonRepresentation/PersonRepresentation';
import { GLYPHS } from '../SVGIcon/SVGIcon';
import { SIZES as thumbnailSizes } from '../Thumbnail/Thumbnail';
import styles from './UserConfirmationList.scss';

const StatusTypes = {
  SUCCESS: 'SUCCESS',
  WARNING: 'WARNING',
  TEXT: 'TEXT'
};

function UserConfirmationList({
  id = '',
  users,
  failedIds = [],
  heading = '',
  warningUserStatusText,
  successUserStatusText,
  noUsersText
}) {
  return (
    <PopoutConfirmationList heading={heading} id={id}>
      {Object.keys(users).length ? (
        Object.entries(users).map(([userId, user]) => {
          const isWarning = failedIds.includes(userId);

          return (
            <div key={userId} id={`${id}-${userId}`} className={styles.confirmationListItem}>
              <ContentWithSVGIcon glyph={isWarning ? GLYPHS.ICON_WARNING_CIRCLE : GLYPHS.ICON_CHECK_CIRCLE}>
                <PersonRepresentation
                  id={`user-${userId}`}
                  name={`${user.firstname || ''} ${user.lastname || ''}`}
                  email={user.email}
                  thumbnailSize={thumbnailSizes.MEDIUM}
                  statusText={isWarning ? warningUserStatusText : successUserStatusText}
                  status={isWarning ? StatusTypes.WARNING : StatusTypes.TEXT}
                  extraInformationIsWarning={isWarning}
                  userUpdateSuccess={!isWarning}
                />
              </ContentWithSVGIcon>
            </div>
          );
        })
      ) : (
        <div className="gin-top2">
          <em>{noUsersText}</em>
        </div>
      )}
    </PopoutConfirmationList>
  );
}

UserConfirmationList.propTypes = {
  id: PropTypes.string,
  heading: PropTypes.string,
  users: PropTypes.object.isRequired,
  failedIds: PropTypes.array,
  warningUserStatusText: PropTypes.string.isRequired,
  successUserStatusText: PropTypes.string.isRequired,
  noUsersText: PropTypes.string.isRequired
};

export default UserConfirmationList;
