import { put } from 'redux-saga/effects';
import { loadOfflineUnits } from '../../../../actions/offlineContentPlayer';

function* deleteOfflineUnits({ unitsIds, userId, shouldClearAll = false }) {
  if (shouldClearAll) {
    localStorage.setItem('offline_units', JSON.stringify([]));
    yield put(loadOfflineUnits([]));
    return;
  }

  const storedValue = localStorage.getItem('offline_units');
  const units = storedValue ? JSON.parse(storedValue) : {};

  const remainingUnits = units.filter(unit => !unitsIds.includes(unit.id) || unit.userId !== userId);

  localStorage.setItem('offline_units', JSON.stringify(remainingUnits));
  yield put(loadOfflineUnits(remainingUnits));
}

export default deleteOfflineUnits;
