import { takeLatest } from 'redux-saga/effects';
import {
  GET_OFFLINE_PRODUCTS,
  GET_OFFLINE_PROFILES,
  GET_OFFLINE_UNITS,
  ADD_OFFLINE_PRODUCTS,
  ADD_OFFLINE_PROFILES,
  ADD_OFFLINE_UNITS,
  DELETE_OFFLINE_UNITS,
  DELETE_OFFLINE_PROFILES,
  GET_OFFLINE_PENDING_ACTIONS_REQUEST,
  CLEAR_OFFLINE_PENDING_ACTIONS_REQUEST,
  GET_PENDING_ACTIONS_TIME,
  SET_PENDING_ACTIONS_TIME,
  GET_OFFLINE_PLAYER_VERSION,
  UPDATE_OFFLINE_PLAYER_VERSION,
  GET_OFFLINE_LICENSE_REQUEST,
  GET_OFFLINE_LICENSE_STATUS_TIMER,
  SET_OFFLINE_LICENSE_STATUS_TIMER
} from '../../../../actionTypes';

import addOfflineProducts from './addOfflineProducts';
import addOfflineProfiles from './addOfflineProfiles';
import addOfflineUnits from './addOfflineUnits';
import getOfflineProducts from './getOfflineProducts';
import getOfflineProfiles from './getOfflineProfiles';
import getOfflineUnits from './getOfflineUnits';
import deleteOfflineUnits from './deleteOfflineUnits';
import deleteOfflineProfiles from './deleteOfflineProfiles';
import getOfflinePendingActions from './getOfflinePendingActions';
import clearOfflinePendingActions from './clearOfflinePendingActions';
import getOfflinePendingActionsTime from './getOfflinePendingActionsTime';
import setOfflinePendingActionsTime from './setOfflinePendingActionsTime';
import getOfflinePlayerVersion from './getOfflinePlayerVersion';
import updateOfflinePlayerVersion from './updateOfflinePlayerVersion';
import getOfflineLicenseStatus from './getOfflineLicenseStatus';
import getOfflineLicenseStatusTimer from './getOfflineLicenseStatusTimer';
import setOfflineLicenseStatusTimer from './setOfflineLicenseStatusTimer';

function* root() {
  yield takeLatest(ADD_OFFLINE_PRODUCTS, ({ payload }) => addOfflineProducts(payload));
  yield takeLatest(ADD_OFFLINE_PROFILES, ({ payload }) => addOfflineProfiles(payload));
  yield takeLatest(ADD_OFFLINE_UNITS, ({ payload }) => addOfflineUnits(payload));

  yield takeLatest(GET_OFFLINE_PRODUCTS, ({ payload }) => getOfflineProducts(payload));
  yield takeLatest(GET_OFFLINE_PROFILES, ({ payload }) => getOfflineProfiles(payload));
  yield takeLatest(GET_OFFLINE_UNITS, ({ payload }) => getOfflineUnits(payload));

  yield takeLatest(DELETE_OFFLINE_UNITS, ({ payload }) => deleteOfflineUnits(payload));
  yield takeLatest(DELETE_OFFLINE_PROFILES, ({ payload }) => deleteOfflineProfiles(payload));

  yield takeLatest(GET_OFFLINE_PENDING_ACTIONS_REQUEST, ({ payload }) => getOfflinePendingActions(payload));

  yield takeLatest(CLEAR_OFFLINE_PENDING_ACTIONS_REQUEST, ({ payload }) => clearOfflinePendingActions(payload));

  yield takeLatest(GET_PENDING_ACTIONS_TIME, ({ payload }) => getOfflinePendingActionsTime(payload));
  yield takeLatest(SET_PENDING_ACTIONS_TIME, ({ payload }) => setOfflinePendingActionsTime(payload));

  yield takeLatest(GET_OFFLINE_PLAYER_VERSION, ({ payload }) => getOfflinePlayerVersion(payload));
  yield takeLatest(UPDATE_OFFLINE_PLAYER_VERSION, ({ payload }) => updateOfflinePlayerVersion(payload));

  yield takeLatest(GET_OFFLINE_LICENSE_REQUEST, ({ payload }) => getOfflineLicenseStatus(payload));
  yield takeLatest(GET_OFFLINE_LICENSE_STATUS_TIMER, ({ payload }) => getOfflineLicenseStatusTimer(payload));
  yield takeLatest(SET_OFFLINE_LICENSE_STATUS_TIMER, ({ payload }) => setOfflineLicenseStatusTimer(payload));
}

export default root;
