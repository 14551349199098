import { API_VERSIONS, JSON_ENCODED } from '@oup/shared-node-browser/constants';
import unsignedFetch from '../util/unsignedFetch';

export default function* fetchClearApi(params) {
  const { userToken } = params;

  const URL = `${__API_BASE__}/open/offline-content/clear`;
  const headers = {
    'Content-Type': JSON_ENCODED,
    Accept: API_VERSIONS.API_VERSION_0_2,
    Authorization: userToken
  };

  return yield unsignedFetch('clearOfflinePendingActions', URL, 'POST', undefined, headers);
}
